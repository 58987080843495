import Pagination from "./Pagination";
import {Language, Region} from "./Locale";
import {TranslationKeys} from "../ui-translations/en";

export interface ITopMemeDevs {
    meme_devs : IMemeDev[]
    pagination : Pagination
}

export interface IMemeDev {
    id: number,
    name: string,
    nick_name: string,
    avatar_url: string,
    created_at: number,
    meme_dev_rating_info: {
        created_memes: number,
        judged_sum: number,
        level: number,
        lost_battles: number,
        position: number,
        rating: number,
        spam: number,
        win_battles: number
    },
    clan: {
        badge_url: string,
        id: number,
        league: string,
        name: string,
    },
    is_blocked: boolean,
    is_premium: boolean,
}

export interface IMemeDevPrivateInfo {
    id: number,
    is_verified: boolean,
    email: string,
    birth_date?: string ,
    lang: Language,
    reg: Region,
    created_at: number,
    prem_expires_at?: number,
    deleted_at?: number,
    summoner?: string,
}

export interface UpdateMemeDevInfo {
    email?: string,
    birth_date?: string,
    lang?: string,
    reg?: string,
    summoner?: string,
}

export interface LevelProgressModel {
    done?: string[],
    in_progress?: string[],
}

export const MaxLevel = 6

export function GetMemeDevRatingStr(rating: number) {
    if (rating > 1_000_000_000) {
        return  Math.round(rating/100_000_000)/10 + "B";
    } else if (rating > 1_000_000) {
        return  Math.round(rating/100_000)/10 + "M";
    }  else if (rating > 1000) {
        return  Math.round(rating/1000) + "K";
    } else {
        return rating.toString();
    }
}

export interface UpdateAvatarData {
    Image: File,
}
export interface UpdateAvatarResponse {
    avatar_url: string,
}

export interface UnblockMemeDevInfo {
    id: number,
}

export interface DeleteAccountResponse {
    deleted_at: number
}