import React from "react";
import cl from "./Btn.module.css";
import expMemeImg from "../../images/meme/download.png";
import {ExportMemeBtnProps} from "./ExportMemeBtn";
import {sendMetric} from "../../../pkg/Metric";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {ErrorNotify} from "../ErrorPage";

export const ExportVideoMemeBtn = (props: ExportMemeBtnProps) => {
    // console.log(props)
    const { t } = useTranslation();
    const handleClick = async () => {
        try {
            toast.info(t("ui.downloadMsg"), {autoClose: 1000});
            // Создать временную ссылку для скачивания
            const link = document.createElement("a");
            link.href = props.meme.url;
            link.download = props.fileName + ".mp4";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Удаляем ссылку из DOM
            if (props.specifyMetricName) {
                sendMetric("goal", props.specifyMetricName);
            } else {
                sendMetric("goal", "download_meme")
            }
        } catch (error) {
            ErrorNotify({err: error as Error})
        }
    };
    return (
        <div className={cl.MemePanelBtn}>
            <img className={cl.MemePanelBtnImg}
                 src={expMemeImg}
                 alt={"export-meme"}
                 onClick={handleClick}/>
        </div>
    );
};