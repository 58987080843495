import cl from "./Modal.module.css"
import React, {CSSProperties} from "react";
import {createPortal} from "react-dom";

interface ModalProps {
    content: JSX.Element,
    contentContainerStyle?: CSSProperties,
    isVisible: boolean,
    setIsVisible(b: boolean): void,
}

export function Modal({content, contentContainerStyle, isVisible, setIsVisible}: ModalProps) {
    return (
        <>
            { createPortal(
                <div className={isVisible ? `${cl.ModalBG} ${cl.active}` : cl.ModalBG}
                     onMouseDown={() => setIsVisible(false)}>
                    <div className={cl.ContentContainer}
                         style={contentContainerStyle}
                         onMouseDown={e => e.stopPropagation()}>
                        { content }
                    </div>
                </div>,
                document.body
            )}
        </>
    );
}