import React, {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import cl from "./LoginRegister.module.css";
import hide_pwd_img from "./images/login-register/hide_pwd.png"
import show_pwd_img from "./images/login-register/show_pwd.png"
import {LoginFormData, OAuthData, RegistrationFormData} from "../views/LoginRegister";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MemeDevService from "../services/MemeDevService";
import {useFetching} from "../hooks/useFetching";
import {ErrorNotify} from "./ui/ErrorPage";
import {toast} from "react-toastify";
import {ValidationObject} from "../models/Error";
import {capitalizeFirstLetter} from "../pkg/Strings";
import {planetEarthRegionCode} from "../models/Locale";
import {sendMetric} from "../pkg/Metric";
import {GoogleLoginButton} from "./ui/Buttons/OAuth/Google";
import {TwitterLoginButton} from "./ui/Buttons/OAuth/Twitter";
import AppleLoginButton from "./ui/Buttons/OAuth/Apple";
import {DiscordLoginButton} from "./ui/Buttons/OAuth/Discord";
import Loader from "./ui/Loader";
import AuthService from "../services/AuthService";
import {toModelOAuthGetUserInfo} from "../converter/LoginRegister";
import {accessTokenName} from "../store/authStore";

export interface LoginRegisterProps {
    visible: boolean,
    setIsVisible(bool: boolean) : void,
    isRegister: boolean,
    setIsRegister(bool: boolean) : void,
}

function LoginRegister({visible, setIsVisible, isRegister, setIsRegister}: LoginRegisterProps) {
    const { t } = useTranslation();
    const {authStore} = useContext(Context);
    const [accessToken, setAccessToken] = useState("");

    const [showModalAgreement, setShowModalAgreement] = useState(false);
    const [isRegisterDone, setIsRegisterDone] = useState(false);
    const [handleModalAgreementConfirm, isModalAgreementConfirmLoading, modalAgreementConfirmError] = useFetching(async () => {
        localStorage.setItem(accessTokenName, accessToken);
        await authStore.setAuth(true);
        setIsRegisterDone(true);
        setShowModalAgreement(false);
    });
    function handleSetModal(b: boolean) {
        setIsVisible(b)
        if (!b) {
            toast.dismiss();
            setIsRegisterDone(false); // reset the state so that after logout this panel is not displayed
        }
    }
    const [handleOAuthGetUser, isOAuthLoading, oauthError] = useFetching(async (data: OAuthData) => {
        try {
            const response = await AuthService.oauthGetUser(toModelOAuthGetUserInfo(data));
            localStorage.setItem(accessTokenName, response.data.access_token);
            setAccessToken(response.data.access_token);
            const userData = await MemeDevService.getMemeDevPrivateInfo();
            if (userData && (userData.created_at + 30 > Date.now() / 1000)) { // if account created 30 or fewer seconds ago
                localStorage.removeItem(accessTokenName);
                setShowModalAgreement(true);
            } else {
                await authStore.setAuth(true);
                handleSetModal(false);
            }
            sendMetric("goal", `auth2_${data.type}_success`)
        } catch (error) {
            sendMetric("goal", `auth2_${data.type}_failed`)
            throw error
        }
    });

    const rootClasses = [cl.form];
    if (visible) {
        rootClasses.push(cl.active);
    }

    useEffect(() => {
        if (authStore.isInited && !authStore.isAuth) {
            const summoner = localStorage.getItem("referral");
            if (summoner) {
                setIsRegister(true);
            }
        } else if (authStore.isInited) {
            setIsVisible(false);
        }
    }, [authStore.isInited]);

    useEffect(() => {
        if (isRegister) {
            sendMetric("goal", "sign_up_window");
        }
        if (!isRegister) {
            sendMetric("goal", "login_window");
        }
    }, [isRegister]);

    useEffect(() => {
        if (oauthError) {
            ErrorNotify({err: oauthError});
        } else if (modalAgreementConfirmError) {
            ErrorNotify({err: modalAgreementConfirmError});
        }
    }, [oauthError, modalAgreementConfirmError]);

    return (
        <div className={rootClasses.join(" ")} onMouseDown={() => handleSetModal(false)}>
            <div className={cl.container} onMouseDown={(e) => e.stopPropagation()}>
                { showModalAgreement &&
                    <ModalAgreement setLoginRegisterModal={handleSetModal}
                                    handleModalAgreementConfirm={handleModalAgreementConfirm}
                                    isModalAgreementConfirmLoading={isModalAgreementConfirmLoading}/> }
                { !showModalAgreement && isRegisterDone && authStore.isAuth &&
                    <AgeInputForm handleSetModal={handleSetModal}/>
                }
                { !showModalAgreement && !isRegisterDone &&
                    <>
                        <div className={cl.topBar}>{isRegister ? t("authForm.signUp.title") : t("authForm.login.title")}</div>
                        <RegisterForm isRegister={isRegister}
                                      setIsRegisterDone={setIsRegisterDone}
                                      isModal={visible}
                                      handleSetModal={handleSetModal}
                                      handleOAuthGetUser={handleOAuthGetUser}
                                      isOAuthLoading={isOAuthLoading}/>
                        <LoginForm isLogin={!isRegister}
                                   handleSetModal={handleSetModal}
                                   handleOAuthGetUser={handleOAuthGetUser}
                                   isOAuthLoading={isOAuthLoading}/>
                        <div className={cl.bottomBar}>
                            <span style={{color: isRegister ? "royalblue" : "darkgrey", cursor: "pointer", fontWeight: "bold"}}
                                  onClick={ () => setIsRegister(false) }>
                                {t("authForm.login.title")}
                            </span>
                            <span style={{whiteSpace: "pre"}}>
                                {` ${t("common.or")} `}
                            </span>
                            <span style={{color: isRegister ? "darkgrey" : "royalblue", cursor: "pointer", fontWeight: "bold"}}
                                  onClick={ () => setIsRegister(true) }>
                                {t("authForm.signUp.title")}
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default observer(LoginRegister);

interface AgeInputFormProps {
    handleSetModal(b: boolean): void,
}

const AgeInputForm = observer(({handleSetModal}: AgeInputFormProps) => {
    const { t } = useTranslation();
    const {authStore} = useContext(Context);
    const [age, setAge] = useState<string>("");
    const [updateAge, isPending, error] = useFetching(async () => {
        if (age !== "") {
            const birthDate = (new Date().getFullYear() - parseInt(age)) + "-01-01";
            authStore.setBirthDate(birthDate);
            await MemeDevService.updateMemeDevInfo({birth_date: birthDate});
        }
        handleSetModal(false);
    });
    useEffect(() => {
        if (error) {
            const props = {allMessages: false, validationObj: "user" as ValidationObject};
            ErrorNotify({err: error, validationErrProps: props});
        }
    }, [error]);

    return (
        <>
            <div className={cl.topBar}>{t("authForm.signUp.enterYourAge")}</div>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (isPending || age === "") return;
                updateAge({});
            }}>
                <div className={cl.formContent}>
                    <Input type={"number"}
                           name={"birthYear"}
                           value={age}
                           handleValueChange={(e) => setAge(e.target.value)}
                           placeHolder={t("authForm.signUp.yourAge")}/>
                    <div style={{paddingBottom: "2vmin"}}>{t("authForm.signUp.birthDateTooltip")}</div>
                    <button type="submit"
                            style={(isPending || age === "") ? {backgroundColor: "lightgray"} : {}}
                            className={cl.button}>
                        {isPending ? t("ui.pending") : t("ui.submit")}
                    </button>
                </div>
            </form>
        </>
    );
});

interface OAuthPanelProps {
    getUser: (datd: OAuthData) => Promise<void>
    isGetUserLoading: boolean,
}

// OAuthPanel should not be mounted twice to avoid duplicate calls of message listeners (in twitter and discord buttons)
const OAuthPanel = observer(({getUser, isGetUserLoading}: OAuthPanelProps) => {
    const { rcStore} = useContext(Context);

    // if (!rcStore.isInited) {
    //     return <div><Loader/></div>
    // }
    if (rcStore.useOAuthBtns)
        return (
            <>
                <div className={cl.OAuthContainer}>
                    <GoogleLoginButton getUser={getUser}/>
                    <AppleLoginButton getUser={getUser}/>
                    <TwitterLoginButton getUser={getUser}/>
                    <DiscordLoginButton getUser={getUser}/>
                </div>
                { isGetUserLoading && <Loader/> }
            </>
        );
    else return (<></>);
});

interface LoginFormProps {
    isLogin: boolean,
    handleSetModal(b: boolean): void,
    handleOAuthGetUser: (data: OAuthData) => Promise<void>
    isOAuthLoading: boolean,
}

const LoginForm = observer(({isLogin, handleSetModal, handleOAuthGetUser, isOAuthLoading}: LoginFormProps) => {
    const {authStore, localeStore} = useContext(Context);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isRestorePwdBar, setIsRestorePwdBar] = useState(false);
    const defLoginFormData : LoginFormData = {
        email: "", password: ""
    }
    const [loginFormData, setLoginFormData] = useState(defLoginFormData);
    const [login, isPending, error] = useFetching(async () => {
        await authStore.login(loginFormData);
        handleSetModal(false);
        setLoginFormData(defLoginFormData);

        //get locale settings and account status
        const info = await MemeDevService.getMemeDevPrivateInfo();
        localeStore.setMemeLanguage(info.lang.code);
        localeStore.setRegion(info.reg.code);
        authStore.setIsVerified(info.is_verified);

        sendMetric("goal", "login");
    })

    useEffect(() => {
        setIsRestorePwdBar(!!error);
        if (error) {
            ErrorNotify({err: error});
        }
    }, [error]);

    if (!isLogin) {
        return <></>
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            login({});
        }}>
            <div className={cl.formContent}>
                <Input
                    type={"text"}
                    name={"memeBattleEmail"}
                    value={loginFormData.email}
                    handleValueChange={e => {
                        setLoginFormData({...loginFormData, email: e.target.value})
                    }}
                    autoComplete={"email"}
                    placeHolder={t("authForm.login.email")}
                    required={true}
                />
                <PwdInput
                    name={"memeBattlePassword"}
                    value={loginFormData.password}
                    handleValueChange={e => {
                        setLoginFormData({...loginFormData, password: e.target.value})
                    }}
                    placeHolder={t("authForm.login.password")}
                    autoComplete={"current-password"}
                    canShown={true}
                />
                <OAuthPanel getUser={handleOAuthGetUser}
                            isGetUserLoading={isOAuthLoading}/>
                <button type="submit"
                        style={(isPending) ? {backgroundColor: "lightgray"} : {}}
                        className={cl.button}>
                    {isPending ? t("ui.pending") : t("authForm.login.title")}
                </button>
            </div>
            {isRestorePwdBar &&
                <div className={cl.RestorePwd}>
                    {`${t("authForm.login.forgotPwd")} `}
                    <span className={cl.Link}
                          onClick={() => {
                              navigate("/restore-password-request");
                              handleSetModal(false);
                          }}>
                        {t("authForm.login.restorePwd")}
                    </span>
                </div>
            }
        </form>
    );
})

interface RegisterFormProps {
    isRegister: boolean,
    setIsRegisterDone(b: boolean): void,
    isModal: boolean,
    handleSetModal(b: boolean): void,
    handleOAuthGetUser: (datd: OAuthData) => Promise<void>
    isOAuthLoading: boolean,

}

export const validationErrorStyle: CSSProperties = {
    boxShadow: "inset 0 0 3px crimson"
}

const RegisterForm = observer(({isRegister, setIsRegisterDone, isModal, handleSetModal, handleOAuthGetUser, isOAuthLoading}: RegisterFormProps) => {
    const {authStore, localeStore} = useContext(Context);
    const { t } = useTranslation();
    const defRegisterFormData: RegistrationFormData = {
        email: "", password: "", nickName: "", lang: "", reg: "", summoner: ""
    }
    const [registerFormData, setRegisterFormData] = useState(defRegisterFormData);
    const [isAgreeWithTerms, setIsAgreeWithTerms] = useState(false);
    const [failFieldList, setFailFieldList] = useState<string[]>([]);
    const [register, isPending, error] = useFetching(async () => {
        await authStore.registration({...registerFormData, lang: localeStore.getMemeLanguage(), reg: localeStore.getRegion()});
        setRegisterFormData(defRegisterFormData);
        setIsRegisterDone(true);
        if (localeStore.getRegion() === "") {
            localeStore.setRegion(planetEarthRegionCode);
        }
        localStorage.removeItem("referral");
        sendMetric("goal", "sign_up");
        toast.success(t("authForm.signUp.successSignUp"), {autoClose: 10000});
    })

    useEffect(() => {
        if (error) {
            const props = {allMessages: true, validationObj: "user" as ValidationObject};
            const filedList = ErrorNotify({err: error, validationErrProps: props});
            setFailFieldList(filedList);
        }
    }, [error]);

    useEffect(() => {
        const referral = localStorage.getItem("referral");
        if (referral) {
            setRegisterFormData({...registerFormData, summoner: referral})
        }
    }, [isModal]);

    if (!isRegister) {
        return <></>
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            register({});
        }}>
            <div className={cl.formContent}>
                <Input
                    type={"text"}
                    name={"nickName"}
                    value={registerFormData.nickName}
                    handleValueChange={(e) =>
                        setRegisterFormData({...registerFormData, nickName: e.target.value})}
                    autoComplete={"nick-name"}
                    placeHolder={t("authForm.signUp.nickname")}
                    required={true}
                    inputStyle={failFieldList.includes("nick_name") ? validationErrorStyle : {}}
                />
                <Input
                    type={"text"}
                    name={"memeBattleEmail"}
                    value={registerFormData.email}
                    handleValueChange={e => {
                        setRegisterFormData({...registerFormData, email: e.target.value})
                    }}
                    autoComplete={"email"}
                    placeHolder={t("authForm.signUp.email")}
                    required={true}
                    inputStyle={failFieldList.includes("email") ? validationErrorStyle : {}}
                />
                <PwdInput
                    name={"memeBattlePassword"}
                    value={registerFormData.password}
                    handleValueChange={e => {
                        setRegisterFormData({...registerFormData, password: e.target.value})
                    }}
                    placeHolder={t("authForm.signUp.password")}
                    inputStyle={failFieldList.includes("password") ? validationErrorStyle : {}}
                    canShown={true}
                />
                <Input
                    type={"text"}
                    name={"summoner"}
                    value={registerFormData.summoner}
                    handleValueChange={(e) =>
                        setRegisterFormData({...registerFormData, summoner: e.target.value})}
                    placeHolder={t("authForm.signUp.summoner")}
                    tooltip={t("authForm.signUp.summonerTooltip")}
                />
                <AgreementCheckbox
                    isAgree={isAgreeWithTerms}
                    setIsAgree={setIsAgreeWithTerms}
                    setLoginRegisterModal={handleSetModal}/>
                <OAuthPanel getUser={handleOAuthGetUser}
                            isGetUserLoading={isOAuthLoading}/>
                <button type="submit"
                        className={cl.button}
                        style={(!isAgreeWithTerms || isPending) ? {backgroundColor: "lightgray"} : {}}
                        disabled={!isAgreeWithTerms || isPending}>
                    {isPending ? t("ui.pending") : t("authForm.signUp.title")}
                </button>
            </div>
        </form>
    );
})

interface InputProps {
    type: "text" | "number",
    name: string,
    autoComplete?: string,
    value: string | number,

    handleValueChange(e: React.ChangeEvent<HTMLInputElement>): void,

    placeHolder: string,
    required?: boolean,
    inputStyle?: CSSProperties,
    tooltip?: string
}

export function Input({type, name, autoComplete, value, handleValueChange, placeHolder, required, inputStyle, tooltip}: InputProps) {
    return (
        <label className={tooltip ? cl.InputContainer + " " + cl.InputContainerWithTooltip : cl.InputContainer}
               data-tooltip={tooltip}
               htmlFor={name}>
            <input className={cl.input}
                   style={inputStyle}
                   type={type}
                   name={name}
                   id={name}
                   autoComplete={autoComplete}
                   onChange={handleValueChange}
                   value={value}
                   placeholder={placeHolder}
                   required={required}
            />
            {required &&
                <span className={cl.RequiredMark}>{" * "}</span>
            }
        </label>
    );
}

interface PwdInputProps {
    name: string,
    value: string,
    canShown?: boolean,
    autoComplete?: string,

    handleValueChange(e: React.ChangeEvent<HTMLInputElement>): void,
    placeHolder: string,
    inputStyle?: CSSProperties,
}

export function PwdInput({name, value, canShown, autoComplete, handleValueChange, placeHolder, inputStyle}: PwdInputProps) {
    const [isShown, setIsShown] = useState(false);
    return (
        <label className={cl.InputContainer}
               htmlFor={name}>
            <input className={cl.input}
                   type={isShown ? "text" : "password"}
                   name={name}
                   id={name}
                   onChange={handleValueChange}
                   value={value}
                   autoComplete={autoComplete}
                   placeholder={placeHolder}
                   required={true}
                   style={inputStyle}
            />
            { canShown &&
                <div className={cl.ShowPwdBtn}
                        onClick={(e) => {
                            e.preventDefault(); // to prevent sending form
                            setIsShown(!isShown)
                        }}>
                    <img className={cl.ShowPwdBtnImg}
                         src={isShown ? hide_pwd_img : show_pwd_img} alt={isShown ? "hide" : "show"}/>
                </div>
            }
            <span className={cl.RequiredMark}>{" * "}</span>
        </label>
    );
}

interface LocaleSelectorProps {
    name: string,
    value: string,
    handleValueChange(e: React.ChangeEvent<HTMLSelectElement>): void,
    description: string,
    options: {code: string, name: string}[],
    required?: boolean,
    inputStyle?: CSSProperties,
    optionStyle?: CSSProperties,
    isNarrowMode?: boolean,
    showAttentionUnsupported?:boolean
}

export function LocaleSelector({name, value, handleValueChange, description, options, required, inputStyle, optionStyle, isNarrowMode, showAttentionUnsupported}: LocaleSelectorProps) {
    const [isSelectorNarrow, setIsSelectorNarrow] = useState(isNarrowMode);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    function handleClickOutside () {
        if (isNarrowMode) {
            setIsSelectorNarrow(true);
        }
        setIsPopupOpen(false);
    }
    function handleClickInside (e: React.MouseEvent<HTMLLabelElement>) {
        e.stopPropagation();
        if (isNarrowMode) {
            setIsSelectorNarrow(!isSelectorNarrow)
        }
        setIsPopupOpen(!!showAttentionUnsupported && !isPopupOpen);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <label className={cl.LocaleLabel}
               htmlFor={name}
               onClick={handleClickInside}>
            <select className={cl.LocaleSelector}
                    style={{paddingLeft: isNarrowMode ? 0 : "", paddingRight: isNarrowMode ? 0 : "", ...inputStyle}}
                    name={name}
                    id={name}
                    value={value}
                    onChange={handleValueChange}
                    required={required}
            >
                <option value="" disabled>{description}</option>
                {options.map((opt) => (
                    <option key={opt.code}
                            value={opt.code}
                            style={optionStyle}>
                        { isSelectorNarrow ? '' : capitalizeFirstLetter(opt.name) }
                    </option>
                ))}
            </select>
            {required && <span className={cl.RequiredMark}>{" * "}</span>}
            {isPopupOpen && <PopUpAttentionUnsupported/>}
        </label>
    );
}

function PopUpAttentionUnsupported() {
    const { t } = useTranslation();
    return (
        <div className={cl.PopUpAttentionContainer}>
            { t("ui.featureTemporarilyDisabled") }
        </div>
    );
}

interface BirthDateInputProps {
    date: string,
    setDate(date: string): void,
    inputStyle?: CSSProperties,
    containerStyle?: CSSProperties,
    hideLabelText?: boolean,
    required?: boolean,
}

export function BirthDateInput({date, setDate, inputStyle, containerStyle, hideLabelText, required}: BirthDateInputProps) {
    const { t } = useTranslation();

    return (
        <label htmlFor="birthDate"
               className={cl.BirthDateInputContainer}
               style={containerStyle}
               data-tooltip={t("authForm.signUp.birthDateTooltip")}>
            {!hideLabelText && t("authForm.signUp.birthDate")}
            <input className={cl.input + " " + cl.BirthDate}
                   style={inputStyle}
                   type="date"
                   name="birthDate"
                   id="birthDate"
                   autoComplete="birthDate"
                   min={getMinBirthDate()} max={getMaxBirthDate()}
                   onInvalid={e =>
                       (e.target as HTMLInputElement).setCustomValidity(t("authForm.signUp.error.age"))}
                   onChange={e => {
                       e.target.setCustomValidity('');
                       setDate(e.target.value)
                   }}
                   value={date}
                   required={required}
            />
            { required && <span className={cl.RequiredMark}>{" * "}</span> }
        </label>
    );
}

function getMaxBirthDate() {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 12); // 12 лет назад
    // console.log("maxdate:", maxDate.toISOString().split('T')[0])
    return maxDate.toISOString().split('T')[0]; // Преобразуем дату в формат YYYY-MM-DD
}

function getMinBirthDate() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100); // 100 лет назад
    // console.log("mindate:", minDate.toISOString().split('T')[0])
    return minDate.toISOString().split('T')[0]; // Преобразуем дату в формат YYYY-MM-DD
}

interface AgreementCheckboxProps {
    isAgree: boolean,
    setIsAgree(b: boolean): void,
    setLoginRegisterModal(b: boolean): void,
}

function AgreementCheckbox({isAgree, setIsAgree, setLoginRegisterModal}: AgreementCheckboxProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className={cl.CheckBoxContainer}>
            <input type={"checkbox"}
                   name={"agreement"}
                   id={"agreement"}
                   checked={isAgree}
                   onChange={() => setIsAgree(!isAgree)}/>
            <label htmlFor={"agreement"}
                   className={cl.CheckboxLabel}>
                {`${t("authForm.signUp.agreement.agree")} `}
                <span className={cl.Link}
                      onClick={() => {
                          navigate("/privacy");
                          setLoginRegisterModal(false);
                      }}>
                    {t("authForm.signUp.agreement.privacy")}
                </span>
                {` ${t("authForm.signUp.agreement.and")} `}
                <span className={cl.Link}
                      onClick={() => {
                          navigate("/terms-of-use");
                          setLoginRegisterModal(false);
                      }}>
                    {t("authForm.signUp.agreement.terms")}
                </span>
                {t("authForm.signUp.agreement.ending")}
            </label>
        </div>
    );
}

interface ModalAgreementProps {
    setLoginRegisterModal(b: boolean): void,
    handleModalAgreementConfirm: ({}) => Promise<void>,
    isModalAgreementConfirmLoading: boolean,
}


function ModalAgreement({setLoginRegisterModal, handleModalAgreementConfirm, isModalAgreementConfirmLoading}: ModalAgreementProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <>
            <div className={cl.topBar}>{t("authForm.signUp.agreementModal.title")}</div>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleModalAgreementConfirm({});
            }}>
                <div className={cl.formContent}>
                    <div className={cl.ModalAgreementContainer}>
                        {`${t("authForm.signUp.agreementModal.byStaying")} `}
                        <span className={cl.Link}
                              onClick={() => {
                                  navigate("/privacy");
                                  setLoginRegisterModal(false);
                              }}>
                            {t("authForm.signUp.agreementModal.privacy")}
                        </span>
                        {` ${t("authForm.signUp.agreementModal.and")} `}
                        <span className={cl.Link}
                              onClick={() => {
                                  navigate("/terms-of-use");
                                  setLoginRegisterModal(false);
                              }}>
                            {t("authForm.signUp.agreementModal.terms")}
                        </span>
                        {t("authForm.signUp.agreementModal.ending")}
                    </div>
                    { isModalAgreementConfirmLoading && <Loader/> }
                    <button type={"submit"}
                            className={cl.button}>
                        {t("authForm.signUp.agreementModal.acceptBtn")}
                    </button>
                </div>
            </form>
        </>
    );
}