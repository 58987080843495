import cl from "./BattleField.module.css"
import {useFetching} from "../../../hooks/useFetching";
import {IBattle, IMeme, IMemeWithMemeDev} from "../../../models/Memes";
import React, {CSSProperties, MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {Headline, MemeBottomPanel, MemeContent, MemeImage} from "../Memes/Memes";
import {JudgeMemeBattle} from "../../../models/MemeBattle";
import ErrorPage from "../../ui/ErrorPage";
import {LoaderPage} from "../../ui/Loader";
import {useTranslation} from "react-i18next";
import MemeBattleService from "../../../services/MemeBattleService";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {useResizing} from "../../../hooks/useResizing";
import {GetElementByCurrentDay} from "../../../pkg/Day";
import {sendMetric} from "../../../pkg/Metric";
import { isMobile } from "react-device-detect";

const emoji = ["⚔️", "⚒️", "⚖️"]

interface BattleFieldProps {
    isAuth: boolean,
    setAuthModal(bool: boolean): void,
    setIsRegister(bool: boolean) : void,
    setIsContentWide(bool: boolean): void,
}

export default observer(BattleField);

function BattleField({isAuth, setAuthModal, setIsRegister, setIsContentWide}: BattleFieldProps) {
    const { t } = useTranslation();
    const { localeStore, rcStore } = useContext(Context);
    const [battles, setBattles] = useState<IBattle[]>([]);
    const [battleIdx, setBattleIdx] = useState(0);
    const [needFetch, setNeedFetch] = useState(true);
    const [isNoMoreBattles, setIsNoMoreBattles] = useState(false);
    
    const signUpInfo = (!isAuth && !isMobile) ? (". " + t("battleField.signUpInfo")) : "";

    const [fetchBattles, isLoading, fetchError] = useFetching(async () => {
        let contentTypes = rcStore.contentTypes

        // exclude mp4 for mobile users, until page will be designed for it
        if (isMobile) {
            let contentArray = contentTypes.split(",");
            let index = contentArray.indexOf("mp4");
            if (index !== -1) {
                contentArray.splice(index, 1);
            }
            contentTypes = contentArray.join(",");
        }

        const battles = await MemeBattleService.getMemeBattleList(localeStore.getLocale(), contentTypes);
        setBattles(battles);
        if (battles.length === 0) {
            setIsNoMoreBattles(true);
        }
    });

    useEffect(() => {
        if (rcStore.isInited && needFetch) {
            fetchBattles({});
            setNeedFetch(false);
            if (battleIdx === 0) {
                sendMetric("goal", "battle_page");
            }
            if (battleIdx > 0) {
                sendMetric("goal", "load_next_battle_pack");
            }
            setBattleIdx(0);
        }
    }, [needFetch, rcStore.isInited]);

    useEffect(() => {
        setIsContentWide(true);
        return () => {
            setIsContentWide(false);
        }
    }, []);

    const [judgeBattle, isPending, judgeError] = useFetching(async (data: JudgeMemeBattle) => {
        const battleRes = await MemeBattleService.judgeMemeBattle(data, localeStore.getRegion());
        sendMetric("goal", "judgement");
        console.log(battleRes);
        if (battleIdx >= battles.length - 1) {
            setNeedFetch(true);
        } else {
            setBattleIdx(battleIdx + 1);
        }
    })

    if (fetchError) {
        return <ErrorPage err={fetchError}/>
    }
    if (judgeError) {
        return <ErrorPage err={judgeError}/>
    }

    return (
        <>
            { battles.length === 0 && isLoading && <LoaderPage/> }
            { battles.length > 0 &&
                <div className={cl.BattlePage}>
                    <div className={cl.Greetings} >
                        <div className={cl.GreetingsText}>
                            {`${GetElementByCurrentDay(emoji)} ${t("battleField.title")}` + signUpInfo}
                        </div>
                        { 
                                // isSignUpInfoOpen &&
                                // <div className={cl.SignUpInfo}>
                                //     <div className={cl.SignUpInfoText}>
                                //         {t("battleField.signUpInfo")}
                                //     </div>
                                //     <CloseBtn btnStyle={{top: "2px", right: "2px"}}
                                //               handleClickButton={() => {
                                //                 setIsSignUpInfoOpen(false);
                                //                 localStorage.setItem("battlePageSignUpInfoClosed", "true");
                                //                 }}/>
                                // </div>
                                // <div className={cl.SignUpInfo + " " + clApp.Tooltip}
                                //      data-tooltip={"Регистрируйся, чтобы не смотреть одни и те же мемы с разных устройств"}>
                                //     <img className={cl.SignUpInfoImg + ` ${isSignUpInfoPulsing ? clApp.Pulsing : ""}`} 
                                //          src={info_img} alt="info" 
                                //          onMouseEnter={() => {
                                //             setIsSignUpInfoPulsing(false);
                                //             localStorage.setItem("battlePageSignUpInfoOpened", "true")
                                //             }}/>
                                // </div>
                            }
                    </div>
                        <div className={cl.BattleContainer}
                             style={window.innerHeight > window.innerWidth ? {height: `${Math.floor(window.innerHeight * 0.65)}px`} : {}}>
                            <BattleMeme meme={battles[battleIdx].battle[0]}
                                        containerStyle={{top: 0, left: 0}}
                                        doWhenMemeReport={async () => await judgeBattle({
                                            win_meme_id: battles[battleIdx].battle[1].id,
                                            lost_meme_id: battles[battleIdx].battle[0].id,
                                            lang: localeStore.getMemeLanguage(),
                                            reg: localeStore.getRegion(),
                                        })}
                            />
                            <BattleMeme meme={battles[battleIdx].battle[1]}
                                        containerStyle={{top: 0, right: 0}}
                                        doWhenMemeReport={async () => await judgeBattle({
                                            win_meme_id: battles[battleIdx].battle[0].id,
                                            lost_meme_id: battles[battleIdx].battle[1].id,
                                            lang: localeStore.getMemeLanguage(),
                                            reg: localeStore.getRegion(),
                                        })}
                            />
                        </div>
                    <ButtonsBar disabled={isPending}
                                isRegistration={battles[battleIdx].is_registration}
                                setAuthModal={setAuthModal}
                                setIsRegister={setIsRegister}
                                leftMemeId={battles[battleIdx].battle[0].id}
                                rightMemeId={battles[battleIdx].battle[1].id}
                                judgeBattle={judgeBattle}/>
                </div>
            }
            { isNoMoreBattles &&
                <div className={cl.Greetings + " " + cl.NoMoreBattles}>
                    <div className={cl.GreetingsText}>
                    { t("battleField.allBattlesJudged") }
                    </div>
                </div>
            }
        </>
    );
}

interface ButtonBarProps {
    disabled: boolean

    leftMemeId: number,
    rightMemeId: number,
    judgeBattle(data: JudgeMemeBattle): void,

    isRegistration: boolean,
    setAuthModal(bool: boolean): void,
    setIsRegister(bool: boolean) : void,
}

function ButtonsBar(props: ButtonBarProps) {
    const { t } = useTranslation();
    const { localeStore } = useContext(Context);
    function winLeft() {
        if (props.isRegistration) {
            props.setAuthModal(true);
            props.setIsRegister(false);
            return;
        }
        props.judgeBattle({
            win_meme_id: props.leftMemeId,
            lost_meme_id: props.rightMemeId,
            lang: localeStore.getMemeLanguage(),
            reg: localeStore.getRegion(),
        });
    }
    function winRight() {
        if (props.isRegistration) {
            props.setAuthModal(true);
            props.setIsRegister(true);
            return;
        }
        props.judgeBattle({
            win_meme_id: props.rightMemeId,
            lost_meme_id: props.leftMemeId,
            lang: localeStore.getMemeLanguage(),
            reg: localeStore.getRegion(),
        });
    }

    return (
        <div className={cl.ButtonsBar}>
            <button className={cl.Button}
                    disabled={props.disabled}
                    style={props.disabled ? {backgroundColor: "lightgray"} : {}}
                    onClick={winLeft}>
                {props.isRegistration ? t("authForm.login.title") : `🡐 ${t("battleField.left")}`}
            </button>
            <div className={cl.ButtonsSeparator}></div>
            <button className={cl.Button}
                    disabled={props.disabled}
                    style={props.disabled ? {backgroundColor: "lightgray"} : {}}
                    onClick={winRight}>
                {props.isRegistration ? t("authForm.signUp.title") : `${t("battleField.right")} 🡒`}
            </button>
        </div>
    );
}

interface BattleMemeProps {
    meme: IMemeWithMemeDev,
    containerStyle: CSSProperties,
    doWhenMemeReport(): Promise<void>,
}

const BattleMeme = observer((props: BattleMemeProps) => {
    const { authStore } = useContext(Context);
    const memeContainerRef= useRef<HTMLDivElement>(null);
    const contentRef= useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState("auto");
    const [isModalMemeImage, setIsModalMemeImage] = useState(false);
    const [forceShowAnimation, setForceShowAnimation] = useState(true);
    const imgRatioRef = useRef(props.meme.img_ratio);

    function handleSetWidth(imgRatio: MutableRefObject<number>, container: MutableRefObject<HTMLDivElement | null>) {
        if (container.current) {
            const w = imgRatio.current * 100 * container.current.clientHeight / container.current.clientWidth;
            console.log("imgratio ", imgRatio.current * 100, "cH ", container.current.clientHeight, "cW ", container.current.clientWidth, "w ", w)
            if (w > 100) {
                setWidth("100%");
            } else if (w <= 0) {
                setWidth("auto");
            } else {
                setWidth(`${w}%`)
            }
        }
    }

    useEffect(() => {
        imgRatioRef.current = props.meme.img_ratio
        handleSetWidth(imgRatioRef, contentRef);
    }, [props.meme.id]);

    useEffect(() => {
        setTimeout(() => {
            handleSetWidth(imgRatioRef, contentRef);
            setForceShowAnimation(false);
        }, 500); // timeout must be equal or greater than expand battle page animation time
    }, []);

    // // resize inner container (this bullshit doesnt work properly, it may capture img_ratio from previous memes
    // useResizing(contentRef, () => {
    //     handleSetWidth(imgRatioRef, contentRef, isVideoFullScreenRef);
    // })

    const battleMeme =
        <>
            <Headline titles={props.meme.titles}
                      contentType={props.meme.content_type}/>
            <div className={`${cl.MemeContentContainer}`}
                 ref={contentRef}>
                <div className={cl.MemeContentContainer1}
                     style={{width: width}}>
                    <MemeContent meme={props.meme}
                                 forceShowAnimation={forceShowAnimation}
                                 autoplay={true}
                                 handleClick={props.meme.content_type === "IMG" || props.meme.content_type === "GIF"
                                     ? () => setIsModalMemeImage(true)
                                     : undefined
                    }/>
                </div>
            </div>
            <div style={isMobile ? {fontSize: "0.8em"}: {}}>
            <MemeBottomPanel isAuth={authStore.isAuth}
                             memeDevId={props.meme.meme_dev_info.id}
                             memeDevNick={props.meme.meme_dev_info.nick_name}
                             memeDevAvatarUrl={props.meme.meme_dev_info.avatar_url}
                             meme={props.meme}
                             scale={isMobile ? 0.7 : 1}
                             doWhenReport={props.doWhenMemeReport}/>
            </div>
        </>

    // const modalMeme =
    //     <div className={cl.ModalMemeContainer}>
    //         <div style={{height: "2vmin"}}></div>
    //         <MemeContent id={props.meme.id}
    //                      url={props.meme.url}
    //                      imgRatio={props.meme.img_ratio}
    //                      titles={props.meme.titles}
    //                      handleClick={() => setIsModal(false)}/>
    //         <MemeBottomPanel isAuth={authStore.isAuth}
    //                          memeDevId={props.meme.meme_dev_info.id}
    //                          memeDevNick={props.meme.meme_dev_info.nick_name}
    //                          memeDevAvatarUrl={props.meme.meme_dev_info.avatar_url}
    //                          meme={props.meme}/>
    //     </div>

    return (
        <>
            <div className={`${cl.MemeContainer}`}
                 ref={memeContainerRef}
                 style={props.containerStyle}>
                { battleMeme }
            </div>
            { isModalMemeImage && <ExpandedModalMemeImage meme={ props.meme }
                                                   isVisible={isModalMemeImage}
                                                   setIsVisible={setIsModalMemeImage}/> }
        </>
    );
})

interface ExpandedModalMemeImageProps {
    meme: IMeme,
    isVisible: boolean,
    setIsVisible(b: boolean): void,
}

function ExpandedModalMemeImage({meme, isVisible, setIsVisible}: ExpandedModalMemeImageProps) {
    return (
        <div className={isVisible ? `${cl.ModalMemeBG} ${cl.active}` : cl.ModalMemeBG}
             onMouseDown={() => setIsVisible(false)}>
            <div className={cl.ModalMemeContainer}
                 onMouseDown={e => e.stopPropagation()}>
                <Headline titles={meme.titles}
                          contentType={meme.content_type}/>
                <MemeImage id={meme.id}
                             url={meme.url}
                             imgRatio={meme.img_ratio}
                             contentType={meme.content_type}
                             titles={meme.titles}
                             handleClick={() => setIsVisible(false)}/>
            </div>
        </div>
    );
}