import {TranslationKeys} from "../ui-translations/en";

export interface MemeDevPrivateInfo {
    isVerified: boolean,
    email: string,
    birthDate: string ,
    // lang: Language,
    // reg: Region,
    createdAt: number,
    premExpiresAt: number | null,
    deletedAt: number | null,
    summoner: string | null,
}

export interface LevelProgress {
    level: number,
    done: string[],
    inProgress: string[],
}

export function GetMemeDevLevelKey(level: number) : keyof TranslationKeys['profile']['level'] {
    switch (level) {
        case 1: return "l1";
        case 2: return "l2";
        case 3: return "l3";
        case 4: return "l4";
        case 5: return "l5";
        case 6: return "l6";
        default: return "l1";
    }
}