import {IMemeDevPrivateInfo, LevelProgressModel} from "../models/MemeDevs";
import {LevelProgress, MemeDevPrivateInfo} from "../views/MemeDevs";

export function toViewMemeDevPrivateInfo(info: IMemeDevPrivateInfo) : MemeDevPrivateInfo {
    return {
        birthDate: info.birth_date || "",
        deletedAt: info.deleted_at ? info.deleted_at : null,
        email: info.email,
        isVerified: info.is_verified,
        premExpiresAt: info.prem_expires_at && (Date.now() / 1000 < info.prem_expires_at) ? info.prem_expires_at : null,
        createdAt: info.created_at,
        summoner: info.summoner ? info.summoner : null
    }
}

export function toViewLevelProgress(lvl: number, lvlProgress: LevelProgressModel) : LevelProgress {
    return {
        level: lvl,
        done: lvlProgress.done ? lvlProgress.done : [],
        inProgress: lvlProgress.in_progress ? lvlProgress.in_progress : [],
    }
}