import {TitleStore} from "./Title/TitleStore";
import {makeAutoObservable, toJS} from "mobx";
import {FormType} from "../../../images/forms/forms";

export class DraftStore {
    // lang: string = "";
    // reg: string = "";
    isLocal: boolean = false;
    imgRatio: number = 1.0;
    src: string = "";
    form: FormType = "x_x";
    tags: string[] = [];
    headlineTitle: string = ""; // for gif and video
    titleStore: TitleStore; // for img

    constructor(titleStore: TitleStore) {
        this.titleStore = titleStore;
        makeAutoObservable(this);
    }

    reset = () => {
        this.setSrc("");
        this.setForm("x_x");
        this.setTags([]);
        this.headlineTitle = "";
        this.titleStore.reset();
    }

    // setLang = (lang: string) => {
    //     this.lang = lang;
    // }
    //
    // getLang = () => {
    //     return toJS(this.lang);
    // }
    //
    // setReg = (reg: string) => {
    //     this.reg = reg;
    // }
    //
    // getReg = () => {
    //     return toJS(this.reg);
    // }

    setIsLocal = (isLocal: boolean) => {
        this.isLocal = isLocal;
    }

    getIsLocal = () => {
        return toJS(this.isLocal);
    }

    setImgRatio = (imgRatio: number) => {
        this.imgRatio = imgRatio;
        // console.log(imgRatio);
    }

    getImgRatio = () => {
        return toJS(this.imgRatio);
    }

    setSrc = (imgSrc: string) => {
        this.src = imgSrc;
    }

    getImgSrc = () => {
        return toJS(this.src);
    }

    setForm = (form: FormType) => {
        this.form = form;
        console.log(this.form);
    }

    getForm = () => {
        return toJS(this.form);
    }

    setTags = (tags: string[]) => {
        this.tags = tags;
    }

    getTags = () => {
        return toJS(this.tags);
    }

    setHeadlineTitle(headlineTitle: string) {
        this.headlineTitle = headlineTitle;
    }

    getHeadlineTitle() {
        return toJS(this.headlineTitle);
    }
}