import React from "react";
import cl from "./Btn.module.css";
import expMemeImg from "../../images/meme/download.png";
import {ExportMemeBtnProps} from "./ExportMemeBtn";
import {sendMetric} from "../../../pkg/Metric";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {ErrorNotify} from "../ErrorPage";

export const ExportGifMemeBtn = (props: ExportMemeBtnProps) => {
    const { t } = useTranslation();
    const handleClick = async () => {
        try {
            toast.info(t("ui.downloadMsg"), {autoClose: 1000});
            const response = await fetch(props.meme.url);
            if (!response.ok) throw new Error("Failed to fetch meme image");

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = props.fileName + ".gif" || 'meme.gif';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Освобождаем Blob URL
            URL.revokeObjectURL(blobUrl);

            if (props.specifyMetricName) {
                sendMetric("goal", props.specifyMetricName);
            } else {
                sendMetric("goal", "download_meme")
            }
        } catch (error) {
            ErrorNotify({err: error as Error})
        }
    };
    return (
        <div className={cl.MemePanelBtn}>
            <img className={cl.MemePanelBtnImg}
                 src={expMemeImg}
                 alt={"export-meme"}
                 onClick={handleClick}/>
        </div>
    );
};