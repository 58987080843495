import {
    DeleteAccountResponse,
    IMemeDev,
    IMemeDevPrivateInfo,
    ITopMemeDevs, LevelProgressModel,
    UpdateAvatarData,
    UpdateAvatarResponse, UpdateMemeDevInfo
} from "../models/MemeDevs";
import $api from "../http";
import IBadge from "../models/Badge";

export default class MemeDevService {
    static async getMemeDevPublicInfo(id: number) {
        const response = await $api.get<IMemeDev>(`/v1/meme-devs/${id}`);
        return response.data
    }
    static async getMemeDevLevelInfo(id: number, level: number, langCode: string) {
        const response = await $api.get<LevelProgressModel>(`/v1/meme-devs/${id}/level?level=${level}&lang=${langCode}`);
        return response.data
    }
    static async getMemeDevBadges(id: number, langCode: string) {
        const response =
            await $api.get<IBadge[]>(`/v1/meme-devs/${id}/badges?lang=${langCode}`);
        return response.data;
    }
    static async getTopMemeDevs(regCode: string, page: number) {
        const limit = 20;
        const response =
            await $api.get<ITopMemeDevs>(`/v1/top/top-meme-devs?reg=${regCode}&offset=${page*limit}&limit=${limit}`);
        return response.data;
    }
    static async updateMemeDevAvatar(data: UpdateAvatarData) {
        const response = await $api.postForm<UpdateAvatarResponse>("/v1/meme-devs/account/avatar", data);
        return response.data;
    }

    static async getMemeDevPrivateInfo() {
        const response = await $api.get<IMemeDevPrivateInfo>(`/v1/meme-devs/account/private`);
        return response.data
    }
    static async updateMemeDevInfo(info: UpdateMemeDevInfo) {
        await $api.post(`/v1/meme-devs/account`, info);
    }
    static async updateMemeDevLanguage(langCode: string) {
        await $api.post(`/v1/meme-devs/account/lang`, {lang: langCode});
    }
    static async updateMemeDevRegion(regCode: string) {
        await $api.post(`/v1/meme-devs/account/reg`, {reg: regCode});
    }

    static async blockMemeDev(id: number) {
        return await $api.post("/v1/meme-devs/black-list", {id: id});
    }
    static async unblockMemeDev(id: number) {
        return await $api.delete("/v1/meme-devs/black-list", {data: {id: id}});
    }
    static async unblockAllMemeDevs() {
        return await $api.delete("/v1/meme-devs/black-list");
    }
    static async getBlockedMemeDevs() {
        const response = await $api.get<IMemeDev[]>("/v1/meme-devs/black-list");
        return response.data;
    }

    static async deleteAccount() {
        const response = await $api.delete<DeleteAccountResponse>("/v1/meme-devs/account/delete");
        return response.data
    }
    static async restoreAccount() {
        return await $api.post("/v1/meme-devs/account/restore");
    }
}