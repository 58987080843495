import cl from "./Builder.module.css";
import React, {createContext, CSSProperties, MutableRefObject, useEffect, useRef, useState} from "react";
import forms, {FormType, Form} from "../../images/forms/forms";
import search_img from "../../images/search.svg";
import expand_img from "../../images/ui/expand.png";
import collapse_img from "../../images/ui/collapse.png";
import arrow_down_img from "../../images/builder/arrow_down.png";
import {useFetching} from "../../../hooks/useFetching";
import {useObserving} from "../../../hooks/useObserving";
import {ITemplate, ITemplatePreview} from "../../../models/Templates";
import ImageLoader from "../../ui/ImageLoader";
import Loader from "../../ui/Loader";
import ErrorPage from "../../ui/ErrorPage";
import Constructor from "./Constructor/Constructor";
import {useTranslation} from "react-i18next";
import TemplateService from "../../../services/TemplateService";
import {DraftStore} from "./Constructor/DraftStore";
import {TitleStore} from "./Constructor/Title/TitleStore";
import {observer} from "mobx-react-lite";
import {ServerErrorImpl} from "../../../views/Error";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {sendMetric} from "../../../pkg/Metric";
import {MediaFile} from "../../../views/Memes";

interface State {
    draftStore: DraftStore,
}

const titleStore = new TitleStore();
const draftStore = new DraftStore(titleStore);

export const DraftContext = createContext<State>({
    draftStore
})

export type ConstructorType = 'Meme' | 'Template';

interface BuilderProps {
    setAuthModal(bool: boolean): void,
}

export const nullFile: MediaFile = {file: null, contentType: ""};

const Builder = observer(({setAuthModal}: BuilderProps) => {
    const [template, setTemplate] = useState<ITemplate | null>(null);
    const [isFirstTemplateFetched, setIsFirstTemplateFetched] = useState(false);
    const [selectedFile, setSelectedFile] = useState<MediaFile>(nullFile);
    const [constructorType, setConstructorType] = useState<ConstructorType>('Meme');

    const [fetchTemplate, isLoading, error] = useFetching(async (p: {id: number}) => {
        const template = await TemplateService.getTemplate(p.id);
        setTemplate(template);
        setSelectedFile(nullFile);
        draftStore.setTags(template.tags.map(tag => `#${tag}`));
        setIsFirstTemplateFetched(true);
    });

    function handleConstructorTypeChange() {
        if (constructorType === "Meme") {
            setConstructorType("Template");
        }
        if (constructorType === "Template") {
            setConstructorType("Meme");
        }
    }

    useEffect(() => {
        if (constructorType === "Meme") {
            sendMetric("goal", "meme_builder_page");
        }
        if (constructorType === "Template") {
            sendMetric("goal", "template_builder_page");
        }
    }, [constructorType]);
    const [memeByTemplateFormOpened, setMemeByTemplateFormOpened] = useState(false);
    const [memeByImageFormOpened, setMemeByImageFormOpened] = useState(false);
    useEffect(() => {
        if (template && !memeByTemplateFormOpened) {
            sendMetric("goal", "meme_by_template_form");
            setMemeByTemplateFormOpened(true);
        }
        if (selectedFile.file && !memeByImageFormOpened) {
            sendMetric("goal", "meme_by_image_form");
            setMemeByImageFormOpened(true);
        }
    }, [template, selectedFile.file]);

    return (
        <DraftContext.Provider value={{draftStore}}>
            <ConstructorSelector constructorType={constructorType}
                                 setConstructorType={handleConstructorTypeChange}/>
            <TemplatePreviewListFilterBar constructorType={constructorType}
                                          fetchTemplate={fetchTemplate}
                                          isFirstTemplateFetched={isFirstTemplateFetched}/>
            <div className={cl.Container}
                 style={{minHeight: "25vh"}}>
                { error && <ErrorPage err={error}/> }
                <Constructor constructorType={constructorType}
                             template={template}
                             setTemplate={setTemplate}
                             setAuthModal={setAuthModal}
                             selectedFile={selectedFile}
                             setSelectedFile={setSelectedFile}/>
                { isLoading && <Loader minHeight={"20vh"}/> }
            </div>
        </DraftContext.Provider>
    );
})

export default Builder;

interface ConstructorSelectorProps {
    constructorType: ConstructorType,
    setConstructorType(): void,
}

function ConstructorSelector({constructorType, setConstructorType}: ConstructorSelectorProps) {
    const { t } = useTranslation();
    return (
        <div className={cl.ConstructorSelectorContainer} style={{
            color: constructorType === "Meme" ? "var(--main-color)" : "rgb(65,120,180)"
        }}>
            <button className={cl.SelectConstructorBtn + " " + cl.Left}
                    onClick={() => setConstructorType()}>
                <img className={cl.SelectConstructorBtnImg} src={arrow_down_img} alt={"arrow_left"}/>
            </button>
            <div className={cl.SelectConstructorBtnText}>
                <div>
                    { constructorType === "Meme" && `🧱 ${t("builder.meme.create")}` }
                    { constructorType === "Template" && `🧬 ${t("builder.tmpl.create")}` }
                </div>
                <div style={{fontSize: "0.65em"}}>
                    { constructorType === "Meme" && t("builder.meme.creationDesc") }
                    { constructorType === "Template" && t("builder.tmpl.creationDesc") }
                </div>
            </div>
            <button className={cl.SelectConstructorBtn + " " + cl.Right}
                    onClick={() => setConstructorType()}>
                <img className={cl.SelectConstructorBtnImg}
                     src={arrow_down_img} alt={"arrow_right"}/>
            </button>
        </div>
    );
}

interface TemplatePreviewListFilterBarProps {
    constructorType: ConstructorType,
    fetchTemplate(p: {id: number}): Promise<void>,
    isFirstTemplateFetched: boolean,
}

interface PreviewListFetchingProps {
    fetchFirstTmpl: boolean,
    page: number,
    tagsFilter: string,
    formFilter: FormType,
}

function TemplatePreviewListFilterBar(props: TemplatePreviewListFilterBarProps) {
    const expandPreviewListBtnWasPressedKey = "expandPreviewListBtnWasPressed";
    const { t } = useTranslation();
    const [tagsFilter, setTagsFilter] = useState("");
    const [formFilter, setFormFilter] = useState<FormType>("x_x");
    const [isPreviewListExpanded, setIsPreviewListExpanded] = useState(false);
    const isExpandPreviewListBtnPulsing = !isPreviewListExpanded && !localStorage.getItem(expandPreviewListBtnWasPressedKey);
    function handleExpandPreviewList() {
        setIsPreviewListExpanded(true);
        disableBodyScroll(document.body, {reserveScrollBarGap: true});
        localStorage.setItem(expandPreviewListBtnWasPressedKey, "true");
    }
    function handleCollapsePreviewList() {
        setIsPreviewListExpanded(false);
        enableBodyScroll(document.body);
    }

    const [previewList, setPreviewList] = useState<ITemplatePreview[]>([]);
    const [pagination, setPagination] = useState<PreviewListPagination>({
        total: 0,
        limit: 0,
        isNoSearchResults: false,
    });
    const [page, setPage] = useState(0);
    const [fetchTemplatePreviews, isLoading, error] = useFetching(async (p: PreviewListFetchingProps) => {
        const data = await TemplateService.getPreviewList("", p.tagsFilter, p.formFilter, p.page);
        if (p.page === 0) {
            setPreviewList(data.template_previews);
        } else {
            setPreviewList(previewList.concat(data.template_previews));
        }
        if(data.template_previews.length === 0 && p.page <= 0) {
            setPagination({total: data.pagination.total, limit: data.pagination.limit, isNoSearchResults: true});
        } else {
            setPagination({total: data.pagination.total, limit: data.pagination.limit, isNoSearchResults: false});
        }
        // autoload first template on first render
        if (p.fetchFirstTmpl && data.template_previews.length > 0) {
            await props.fetchTemplate({id: data.template_previews[0].id});
        }
    });

    useEffect(() => {
        fetchTemplatePreviews({fetchFirstTmpl: true, page: page, formFilter: formFilter, tagsFilter: tagsFilter});
        // this unlocks scroll if user, handleCollapsePreviewList() will not be called,
        return () => {
            enableBodyScroll(document.body);
        }
    }, [])

    const lastElement = useRef<HTMLDivElement | null>(null);
    useObserving(lastElement, page*pagination.limit < pagination.total, isLoading, () => {
        fetchTemplatePreviews({fetchFirstTmpl: false, page: page+1, formFilter: formFilter, tagsFilter: tagsFilter});
        if (page > 0) {
            sendMetric("goal", "templates_pagination");
        }
        setPage(page + 1);
    });

    function handleSearch() {
        fetchTemplatePreviews({fetchFirstTmpl: false, page: 0, formFilter: formFilter, tagsFilter: tagsFilter});
        sendMetric("goal", "templates_search");
        setPage(0);
    }

    return (
        <>
            {/*dummy element to replace previewList bar when it expanded*/}
            { isPreviewListExpanded && <div className={cl.Container} style={{height: "calc(13em + 22px)"}}></div> }
            <div className={isPreviewListExpanded ? `${cl.ModalBG} ${cl.active}` : ""}
                 onMouseDown={() => handleCollapsePreviewList()}>
                <div className={cl.Container}
                     onMouseDown={e => e.stopPropagation()}
                     style={isPreviewListExpanded ? {width: "900px", maxWidth: "95%", padding: "1em"} : {}}>
                    <div style={{display: "none"}}>
                        <div className={cl.FormIconListTitle}>{`${t("builder.searchTmpl.filterByForm")}:`}</div>
                        <div className={cl.FormIconListContainer}
                             style={isPreviewListExpanded ? {justifyContent: "flex-start", gap: "1em"} : {justifyContent: "space-between"}}>
                            {forms.slice(0, forms.length - 1).map((form) =>
                                <FormIcon key={form.formType}
                                          formFilter={formFilter}
                                          setFormFilter={(f: FormType) => {
                                              fetchTemplatePreviews({fetchFirstTmpl: false, page: 0, formFilter: f, tagsFilter: tagsFilter});
                                              setFormFilter(f);
                                              setPage(0);
                                          }}
                                          svg={form.svg} formType={form.formType}/>)}
                        </div>
                    </div>
                    <div className={cl.TagsFilterContainer}>
                        <div className={cl.TagsFilterInputContainer}>
                            <input className={cl.TagsFilterInput}
                                   name="tags"
                                   onChange={e => setTagsFilter(e.target.value)}
                                   value={tagsFilter}
                                   type="text"
                                   placeholder={t("builder.searchTmpl.searchByTags")}
                                   onKeyDown={e => {
                                       if (e.key === 'Enter') {
                                           e.preventDefault();
                                           handleSearch();
                                       }
                                   }}
                            />
                            <button className={cl.SearchBtn}
                                    onClick={handleSearch}>
                                <img className={cl.SearchImg} src={search_img} alt={"search_img"}/>
                            </button>
                        </div>
                        <button className={cl.ExpandPreviewListBtn + ` ${isExpandPreviewListBtnPulsing ? cl.Pulsing : ""}`}
                                title={isPreviewListExpanded ? t("builder.searchTmpl.collapsePreviewList") : t("builder.searchTmpl.expandPreviewList")}
                                onClick={() => {isPreviewListExpanded ? handleCollapsePreviewList() : handleExpandPreviewList()}}>
                            { isPreviewListExpanded
                                ? <img className={cl.ExpandPreviewListBtnImg}
                                       src={collapse_img} alt={"collapse"}/>
                                : <img className={cl.ExpandPreviewListBtnImg}
                                       src={expand_img} alt={"expand"}/>
                            }
                        </button>
                    </div>
                    <TemplatePreviewList isExpanded={isPreviewListExpanded}
                                         setIsExpanded={setIsPreviewListExpanded}
                                         constructorType={props.constructorType}
                                         previewList={previewList}
                                         observingLastElement={lastElement}
                                         isLoading={isLoading}
                                         fetchingError={error}
                                         isNoSearchResults={pagination.isNoSearchResults}
                                         fetchTemplate={props.fetchTemplate}
                                         isFirstTemplateFetched={props.isFirstTemplateFetched}/>
                    <div className={cl.ContainerCover} style={{
                        opacity: props.constructorType === "Template" ? 1 : 0,
                        zIndex: props.constructorType === "Template" ? 1 : -1,
                    }}></div>
                </div>
            </div>
        </>
    );
}

interface FormIconProps extends Form {
    formFilter: FormType,
    setFormFilter: (t: FormType) => void,
}

function FormIcon({svg, formType, formFilter, setFormFilter}: FormIconProps) {
    return (
        <div className={cl.FormIconContainer} onClick={() => setFormFilter(formType)}
             style={{backgroundColor: formType === formFilter ? "var(--main-color)" : "white"}}>
            { svg({size: "70%", color: formType === formFilter ? "white" : "var(--std-text-color)"}) }
        </div>
    );
}

interface TemplatePreviewListProps {
    isExpanded: boolean,
    setIsExpanded(b: boolean): void,
    constructorType: ConstructorType,
    previewList: ITemplatePreview[],
    observingLastElement: MutableRefObject<HTMLDivElement | null>,
    isLoading: boolean,
    fetchingError: ServerErrorImpl | null,
    isNoSearchResults: boolean,
    fetchTemplate(p: {id: number}): Promise<void>,
    isFirstTemplateFetched: boolean,
}

interface PreviewListPagination {
    total: number,
    limit: number,
    isNoSearchResults: boolean,
}

function TemplatePreviewList(props : TemplatePreviewListProps) {
    const { t } = useTranslation();

    const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
    async function handleClickOnPreview(previewId: number) {
        await props.fetchTemplate({id: previewId});
        setSelectedTemplateId(previewId);
        props.setIsExpanded(false);
        enableBodyScroll(document.body);
    }

    let previewListScrollStyle: CSSProperties
    if (props.isExpanded) {
        previewListScrollStyle = {
            height: "calc(100vh - 12em)",
            width: "100%",
            minHeight: "80px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            gap: "4em",
            overflowX: "hidden",
            overflowY: "scroll",
        }
    } else {
        previewListScrollStyle = {
            minHeight: "80px",
            display: "flex",
            flexDirection: "row",
            overflowX: "scroll",
            overflowY: "hidden",
            gap: "0.5em",
        }
    }

    return (
        <div className={cl.PreviewList}
             style={props.isExpanded ? {padding: "1em 0 0 0"} : {}}>
            <div className={cl.PreviewListScroll}
                 style={previewListScrollStyle}>
                { props.fetchingError && <ErrorPage err={props.fetchingError}/> }
                { props.previewList.map(
                    (preview, index) =>
                        <div key={preview.id}
                                style={props.isExpanded ? {height: "10em", minWidth: "25%"} : {height: "5em"}}>
                            <TemplatePreview preview={preview}
                                                handleClick={handleClickOnPreview}
                                                selectedTemplateId={selectedTemplateId}/>
                            { props.isExpanded &&
                                <div className={cl.TemplateTagList}>
                                    {preview.tags.slice(0, 2).map((tag, index) =>
                                        <div key={tag}
                                                className={cl.TemplateTag}>
                                            {`#${tag.length > 30 ? tag.substring(0, 28) + "..." : tag}`}
                                        </div>)}
                                </div>
                            }
                        </div>)
                }
                { props.isLoading && <div className={cl.PreviewListInfo}><Loader/></div>}
                { props.isNoSearchResults &&
                    <>
                        <div style={{width: "10px"}}></div>
                        <div className={cl.PreviewListInfo}>{ t("builder.searchTmpl.noSearchResults") }</div>
                    </>
                }
                <div ref={props.observingLastElement} style={{height: "5em", width: "10px", opacity: "0"}}>{"..."}</div>
            </div>
            <div className={cl.ContainerCover} style={{
                opacity: props.constructorType === "Template" ? 1 : 0,
                zIndex: props.constructorType === "Template" ? 1 : -1,
            }}></div>
        </div>
    );
}

interface TemplatePreviewProps {
    preview: ITemplatePreview,
    handleClick(id: number): void
    selectedTemplateId: number | null,
}

function TemplatePreview({preview, handleClick, selectedTemplateId} : TemplatePreviewProps) {
    return (
        <div className={cl.TemplatePreviewContainer}
             onClick={() => handleClick(preview.id)}
             style={{filter: selectedTemplateId === preview.id ? "brightness(70%)" : "none",}}>
            <ImageLoader url={preview.preview_url} alt={"preview_img"}
                         imgStyle={{height: "100%", boxShadow: "0 0 2px rgba(0,0,0,10%)"}}
                         animStyle={{height: "100%", width: "9vmin"}}/>
        </div>
    );
}