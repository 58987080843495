import React, {useState, useEffect} from "react";
import {Modal} from "../Modal";
import cl from "./Specials.module.css";
import {useTranslation} from "react-i18next";

interface NewYearCountdownProps {
    isVisible: boolean,
    setIsVisible(b: boolean): void,
}

const NewYearCountdown = ({isVisible, setIsVisible}: NewYearCountdownProps) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [isNewYear, setIsNewYear] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setInterval(() => {
            const remainingTime = calculateTimeLeft();
            if (
                remainingTime.days <= 0 &&
                remainingTime.hours <= 0 &&
                remainingTime.minutes <= 0 &&
                remainingTime.seconds <= 0
            ) {
                setIsNewYear(true);
                clearInterval(timer);
            } else {
                setTimeLeft(remainingTime);
            }
        }, 100);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const now = new Date();
        const newYearDate = new Date(`January 1, 2025 00:00:00`);
        const difference = newYearDate.getTime() - now.getTime();

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return { days, hours, minutes, seconds };
    }

    return (
        <Modal isVisible={isVisible}
               setIsVisible={setIsVisible}
               contentContainerStyle={{maxWidth: "600px"}}
               content={
                   <div className={cl.CountdownContainer}>
                       {isNewYear ? (
                           <>
                               <div className={cl.Greetings}>{t("specials.greetings")}</div>
                               <ConfettiAnimation/>
                           </>
                       ) : (
                           <>
                               <div className={cl.Countdown}>{t("specials.countdown")}</div>
                               <div className={cl.Timer}>{`${timeLeft.days} ${t("specials.days")} ${timeLeft.hours} ${t("specials.hours")} ${timeLeft.minutes} ${t("specials.minutes")} ${timeLeft.seconds} ${t("specials.seconds")}`}</div>
                               <div className={cl.LetsWait}>{t("specials.letsWait")}</div>
                           </>
                       )}
                   </div>
               }/>
    );
};

export default NewYearCountdown;

const ConfettiAnimation = () => {
    return (
        <div className="confetti">
            {Array.from({ length: 500 }).map((_, i) => (
                <div key={i}
                     className="confetti-piece"
                     style={{
                         backgroundColor: `hsl(${Math.random() * 360}, 70%, 50%)`,
                         left: `${Math.random() * 100}%`,
                         top: `${Math.random() * 200 - 200}%`,
                     }}>
                </div>))}
            <style>{`
        .confetti {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          overflow: hidden;
        }
        .confetti-piece {
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: hsl(${Math.random() * 360}, 70%, 50%);
          top: -10%;
          left: ${Math.random() * 100}%;
          opacity: 1;
          animation: confetti-fall 3s linear forwards;
        }
        @keyframes confetti-fall {
          0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
          }
          100% {
            transform: translateY(200vh) rotate(360deg);
            opacity: 0; /* Исчезновение к концу */
          }
        }
      `}</style>
        </div>
    );
};