import {contentType, CreateMemeInfo, ITitle} from "../models/Memes";
import {DraftStore} from "../components/content/Builder/Constructor/DraftStore";

export interface CreateMemeProps {
    contentType: contentType,
    templateId?: number,        // required if creating from existing template, otherwise not. Only for img
    withTemplate: boolean,      // only for img
    langCode: string,
    regCode: string,
    fontList: string[],
}

export function toModelCreateMemeInfo(draft: DraftStore, props: CreateMemeProps) {
    const isLocal = draft.getIsLocal();
    let titles: ITitle[] = [];
    if (props.contentType === "IMG" || props.templateId) {
        titles = draft.titleStore.getTitles();
        titles = isLocal ? titles.filter(title => title.text.length > 0) : titles; //drop empty titles if meme is local
    } else if (draft.getHeadlineTitle().length > 0) {
        titles = [{
            angle: 0,
            font: props.fontList[0],
            font_size: 0.25,
            text: draft.getHeadlineTitle(),
            text_color: 4278190080,
            x_ratio: 0.5,
            y_ratio: 0.5
        }];
    }
    const formattedTags = draft.tags.map(tag => tag.replace("#", ""));

    const createInfo : CreateMemeInfo = {
        titles: titles,
        img_ratio: draft.imgRatio,
        is_local: isLocal,
        lang: props.langCode,
        reg: props.regCode,
        meme_addon: {
            tags: formattedTags,
        },
    }

    if (props.contentType === "IMG" && props.withTemplate && !props.templateId) {
        createInfo.template_addon = {
            form: draft.getForm(),
            tags: formattedTags,
        };
    }

    if (props.templateId) {
        createInfo.template_id = props.templateId;
    }

    return createInfo;
}