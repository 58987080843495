import {oauthConfig} from "../../../../config";
import {MutableRefObject, useContext, useEffect} from "react";
import {generateBase64UrlEncode, getURLWithQueryParams} from "../../../../pkg/Strings";
import pkceChallenge from "pkce-challenge";
import {useFetching} from "../../../../hooks/useFetching";
import {OAuthData} from "../../../../views/LoginRegister";
import {ErrorNotify} from "../../ErrorPage";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import cl from "./OAuthBtn.module.css";
import twitter_logo_img from "../../../images/navbar/x_logo.png"
import {sendMetric} from "../../../../pkg/Metric";

const AUTH_URL = "https://twitter.com/i/oauth2/authorize"
const SCOPE = ["users.read", "tweet.read", "offline.access"].join(" ")
const REDIRECT_URI = oauthConfig.TwitterRedirectURI

const getTwitterOAuthUrl = (challenge: string) =>
    getURLWithQueryParams(AUTH_URL, {
        response_type: "code",
        client_id: oauthConfig.TwitterClientID,
        redirect_uri: REDIRECT_URI,
        scope: SCOPE,
        state: generateBase64UrlEncode(),
        code_challenge: challenge,
        code_challenge_method: "S256",
    })

interface TwitterLoginButtonProps {
    getUser: (data: OAuthData) => Promise<void>
}

export const TwitterLoginButton = observer(({getUser}: TwitterLoginButtonProps) => {
    const {localeStore} = useContext(Context);

    const openTwitterAuthPopup = async () => {
        const width = 500;
        const height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const { code_verifier, code_challenge } = await pkceChallenge();
        localStorage.setItem('code_verifier', code_verifier);

        const twitterAuthUrl = getTwitterOAuthUrl(code_challenge);
        // console.log(twitterAuthUrl)
        // Открываем новое окно для твиттера
        const popup = window.open(
            twitterAuthUrl,
            'TwitterAuth',
            `width=${width},height=${height},top=${top},left=${left}`
        );

        // Проверяем, закрылось ли popup окно
        const intervalId = setInterval(() => {
            if (popup && popup.closed) {
                clearInterval(intervalId);
                console.log('Авторизация завершена или окно закрыто');
            }
        }, 500);
    };

    // Функция для получения кода из URL, когда popup окно делает редирект
    const receiveMessageTwitter = async (event: MessageEvent) => {
        if (event.origin !== window.location.origin) return; // Проверяем, что сообщение пришло с того же домена

        const { code, provider } = event.data;
        if (code && provider === "twitter") {
            // console.log('Получен код авторизации:', code);
            await getUser({
                accessToken: "",
                avatarUrl: "",
                email: "",
                emailVerified: false,
                lang: localeStore.memeLanguage,
                name: "",
                nickName: "",
                getOAuthData: {
                    redirect_uri: REDIRECT_URI,
                    code: code,
                    code_verifier: localStorage.getItem("code_verifier") || ""
                },
                reg: localeStore.region,
                type: "twitter",
                userId: ""
            })
            // fetchAccessToken(code);
        }
    };
    useEffect(() => {
        // Подписываемся на события postMessage
        window.addEventListener('message', receiveMessageTwitter);

        return () => {
            // Убираем подписку на события при размонтировании компонента
            window.removeEventListener('message', receiveMessageTwitter);
            localStorage.removeItem("code_verifier")
        };
    }, []);

    return (
        <div onClick={() => {
            sendMetric("goal", `auth2_twitter_start`);
            openTwitterAuthPopup();
        }}
             className={cl.OAuthButton}>
            <div className={cl.OAuthImgContainer}>
                <img className={cl.OAuthLogoImg} src={twitter_logo_img} alt={"login with X(twitter)"}/>
            </div>
        </div>
    );
});