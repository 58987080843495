import IMemeFull, {contentType, IMeme, IMemeWithMemeDev, ITitle, MemeRatingInfo} from "../../../models/Memes"
import cl from "./Memes.module.css"
import React, {CSSProperties, RefObject, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import hot_img from "../../images/hot.png";
import rating_img from "../../images/meme/win_rate.png";
import win_img from "../../images/meme/wins.png";
import lost_img from "../../images/meme/defeats.png";
import local_img from "../../images/meme/local.png";
import {useFetching} from "../../../hooks/useFetching";
import {useObserving} from "../../../hooks/useObserving";
import Loader, {LoaderLastElement, LoaderPage} from "../../ui/Loader";
import ErrorPage from "../../ui/ErrorPage";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import ImageLoader from "../../ui/ImageLoader";
import {
    getCorrectedFontAndTextAreaSize,
    getTextAreaPosition,
    Size
} from "../Builder/Constructor/Title/TitleStore";
import {useResizing} from "../../../hooks/useResizing";
import {useWindowScrollResizing} from "../../../hooks/useWindowScrollResizing";
import ShareBtn from "../../ui/Buttons/ShareBtn";
import {useTranslation} from "react-i18next";
import MemeService from "../../../services/MemeService";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {contentContainerId, getMemePageLink} from "../../Content";
import {GetElementByCurrentDay} from "../../../pkg/Day";
import BadgeService from "../../../services/BadgeService";
import def_avatar_img from "../../images/profile/default-avatar.png";
import global_rating_img from "../../images/meme/global_win_rate.png";
import AdditionallyBtn from "../../ui/Buttons/AdditionallyBtn";
import {ReportBtn} from "../../ui/Buttons/Report";
import ShowTagsBtn from "../../ui/Buttons/ShowTagsBtn";
import TagService from "../../../services/TagService";
import {toast} from "react-toastify";
import i18n from "../../../ui-translations/i18n";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {sendMetric} from "../../../pkg/Metric";
import { PopUpInfo } from "../../ui/PopUpInfo";
import {defHotMemesCache} from "../../../store/cache";
import {ExportMemeBtn} from "../../ui/Buttons/ExportMemeBtn";

const emoji = ["🏆", "😆", "🎯", "💎", "😅", "🔥", "🤩", "😂"];

interface HotMemesProps {
    modalMemeIdInUrl?: boolean,
    setAuthModal(bool: boolean): void,
}

function HotMemes({modalMemeIdInUrl, setAuthModal}: HotMemesProps) {
    const { t } = useTranslation();
    const { authStore, localeStore, rcStore, cache } = useContext(Context);
    let cachedHotMemes = cache.getHotMemes(); // cache should be cleaned by clicking at the navbar hot-memes link
    if (cachedHotMemes.lang !== localeStore.getMemeLanguage()) {
        cachedHotMemes = defHotMemesCache;
        // Don't call cache.resetHotMemes() here - for some reason this causes a hang
        // when reassigning the cachedTopMemes variable at the same time.
        // Memes cache will be overwritten after the first page load
    }

    const [memes, setMemes] = useState<IMemeFull[]>(cachedHotMemes.memes);
    const [limit, setLimit] = useState(cachedHotMemes.limit);
    const [total, setTotal] = useState(cachedHotMemes.total);
    const [page, setPage] = useState<number>(cachedHotMemes.page);
    const lastElement = useRef<HTMLDivElement | null>(null);
    const [isNoMoreMemes, setIsNoMoreMemes] = useState(false);
    const params = useParams();
    const modalMemeId = (modalMemeIdInUrl && params.id) ? parseInt(params.id) : null;

    const [fetchMemes, isLoading, error] = useFetching(async (p: {page: number}) => {
        const data = await MemeService.getTopMemes(localeStore.getLocale(), p.page, rcStore.contentTypes);
        const concatMemes = memes.concat(data.memes);
        setMemes(concatMemes);
        setTotal(data.pagination.total);
        setLimit(data.pagination.limit);
        if (data.memes.length === 0) {
            setIsNoMoreMemes(true);
        }
        if (p.page === 0) {
            sendMetric("goal", "hot_memes_page");
        }
        if (p.page > 0) {
            sendMetric("goal", "hot_memes_pagination");
        }
    });

    useLayoutEffect(() => {
        return () => {
            cache.setHotMemes({memes: memes, lang: localeStore.getMemeLanguage(), page: page, limit:limit, total:total, scrollY:window.scrollY});
        };
    }, [memes, page, limit, total]);

    useEffect(() => {
        if (rcStore.isInited && cachedHotMemes.memes.length === 0) {
            fetchMemes({page: 0});
        }
        const contentContainer = document.getElementById(contentContainerId);
        if (cachedHotMemes.memes.length > 0 && contentContainer && cachedHotMemes.scrollY < contentContainer.clientHeight) { // last check for preserve overscroll if user changed window height
            window.scrollTo(0, cachedHotMemes.scrollY);
        }
    }, [rcStore.isInited]);


    useObserving(lastElement, page*limit < total, isLoading, () => {
        fetchMemes({page: page + 1});
        setPage(page + 1);
    });

    useWindowScrollResizing();

    return (
        <>
            { modalMemeId && <ModalMeme id={ modalMemeId }/> }
            {/* <div className={cl.Container + " " + cl.Greetings}>
                <div className={cl.GreetingsText}>
                    {`${GetElementByCurrentDay(emoji, 0)} ${t("topMemes.title")}`}
                </div>
            </div> */}
            { memes.map((meme, index) =>
                <TopMeme key={meme.id}
                         isAuth={authStore.isAuth}
                         ratingType={"percent"}
                         meme={meme}
                         position={index + 1}/>)
            }
            { isNoMoreMemes && !authStore.isAuth &&
                <div className={cl.LoginLink}
                     onClick={() => setAuthModal(true)}>
                    {t("topMemes.loginToSeeMore")}
                </div>
            }
            { memes.length === 0 && <LoaderPage/> }
            <LoaderLastElement lastElemRef={lastElement}
                               isTransparent={!isLoading || memes.length === 0}/>
            { error && <ErrorPage err={error}/> }
        </>
    );
}

export default observer(HotMemes);

export const DuncanChestMemes = observer(() => {
    const navigate = useNavigate();

    const { t} = useTranslation();
    const { authStore, localeStore, rcStore } = useContext(Context);
    const [memes, setMemes] = useState<IMemeFull[]>([]);
    const [fetchMemes, isLoading, error] = useFetching(async () => {
        setMemes(await MemeService.getDuncanChestMemes({
            langCode: localeStore.getMemeLanguage(),
            regCode: localeStore.getRegion()},
        rcStore.contentTypes));
    });

    useEffect(() => {
        navigate("/hot-memes")
        // setTimeout(() => {navigate("/hot-memes")}, 25000)
        if (rcStore.isInited) {
            fetchMemes({});
        }
    }, [rcStore.isInited]);
    useWindowScrollResizing();

    return (
        <>
            <div className={cl.Container + " " + cl.Greetings}>
                <div className={cl.GreetingsText}>
                    {`${GetElementByCurrentDay(emoji, 3)} ${t("duncanChest.title")}`}
                </div>
            </div>
            { memes.map((meme, index) =>
                <TopMeme key={meme.id}
                         isAuth={authStore.isAuth}
                         ratingType={"percent"}
                         meme={meme}
                         position={index + 1}/>)
            }
            { isLoading && <LoaderPage/> }
            { error && <ErrorPage err={error}/> }
        </>
    );
})

export interface ModalMemeProps {
    id: number,
}

export function ModalMeme({id}: ModalMemeProps) {
    const [isModal, setIsModal] = useState(true);
    const [imgRatio, setImgRatio] = useState(1);
    // const touchStartX = useRef<number | null>(null);
    // const touchStartY = useRef<number | null>(null);

    // const memeContainer = useRef<HTMLDivElement | null>(null);
    //
    // useResizing(memeContainer, () => {
    //     const cur = memeContainer.current;
    //     if (cur && cur.clientHeight > window.innerHeight) {
    //         const ratio = cur.clientHeight*1.1/window.innerHeight;
    //         cur.style.width = Math.floor(cur.clientWidth/ratio) + "px"
    //     }
    // })

    // this unlocks scroll if user, handleClose() will not be called,
    // for example, if the user follows the profile link
    useEffect(() => {
        disableBodyScroll(document.body, {reserveScrollBarGap: true});
        return () => {
            enableBodyScroll(document.body);
        }
    }, []);

    function handleClose() {
        enableBodyScroll(document.body);
        setIsModal(false);
    }

    // const handleTouchStart = (e: React.TouchEvent) => {
    //     touchStartX.current = e.targetTouches[0].clientX;
    //     touchStartY.current = e.targetTouches[0].clientY;
    // };
    // const handleTouchEnd = (e: React.TouchEvent) => {
    //     if (touchStartX.current === null || touchStartY.current === null || window.innerWidth < 50) {
    //         return;
    //     }
    //     const touchEndX = e.changedTouches[0].clientX;
    //     const touchEndY = e.changedTouches[0].clientY;
    //     if (touchStartX.current < 40 && touchEndX > touchStartX.current + 30) {
    //         // Свайп от края экрана вправо
    //         if (Math.abs(touchEndY - touchStartY.current) < touchEndX - touchStartX.current) {
    //             handleClose();
    //         }
    //     } else if (touchStartX.current > window.innerWidth - 30 && touchEndX < touchStartX.current - 30) {
    //         // Свайп от правого края экрана влево
    //         if (Math.abs(touchEndY - touchStartY.current) < touchStartX.current - touchEndX) {
    //             handleClose();
    //         }
    //     }
    // };

    console.log(window.innerHeight)

    return (
        <div className={isModal ? `${cl.ModalMemeBG} ${cl.active}` : cl.ModalMemeBG}
             onMouseDown={handleClose}
             // onTouchStart={handleTouchStart}
             // onTouchEnd={handleTouchEnd}
        >
            <div className={cl.ModalMemeContainer}
                 style={{width: `${window.innerHeight * imgRatio*0.88}px`}}
                 // ref={memeContainer}
                 onMouseDown={e => e.stopPropagation()}>
                <SingleMeme id={id}
                            handleClickOnContent={handleClose}
                            setImgRatio={setImgRatio}/>
            </div>
        </div>
    );
}

interface MemeRatingProps {
    ratingInfo: MemeRatingInfo,
    ratingType: "rating" | "wins" |"losts",
    ratingValueStyle?: CSSProperties,
}

export function MemeRating({ratingInfo, ratingType, ratingValueStyle}: MemeRatingProps) {
    const { t } = useTranslation();
    const btnRef = useRef<HTMLDivElement | null>(null);
    function getValues(): [val: string, imgScr: string, text: string] {
        if (ratingType === "rating") {
            return [Math.round(ratingInfo.rating * 100) + "%", 
                ratingInfo.is_global ? global_rating_img : rating_img, 
                ratingInfo.is_global ? t("ui.memeRatingTitleGlobal") : t("ui.memeRatingTitleLocal")]
        }
        if (ratingType === "wins") {
            return [`${ratingInfo.win_battles}`, win_img, t("ui.memeWins")]
        }
        if (ratingType === "losts") {
            return [`${ratingInfo.lost_battles}`, lost_img, t("ui.memeLosts")]
        }
        return ["", "", ""]
    }
    const [val, imgScr, text] = getValues();

    return (
        <div className={cl.MemeRating} 
            ref={btnRef}>
            <div className={cl.MemeRatingImgContainer} >
                <img className={cl.MemeRatingImg} src={imgScr} alt={"rating_img"}/>
                <PopUpInfo openBtnRef={btnRef}
                           children={text}/>
            </div>
            <div style={ratingValueStyle}>{val}</div>
        </div>
    );
}

export function SingleMemeWrapperWithIdInUrl() {
    const params = useParams();
    const memeId = params.id ? parseInt(params.id) : null;
    return (
        <>
            { memeId && <SingleMeme id={memeId}/> }
        </>
    );
}

export interface SingleMemeProps {
    id: number,
    setImgRatio?(n: number): void, // info for a parent component
    handleClickOnContent?(): void,
}

export const SingleMeme = observer(({id, setImgRatio, handleClickOnContent}: SingleMemeProps) => {
    const { t } = useTranslation();
    const { authStore, localeStore} = useContext(Context);
    const [meme, setMeme] = useState<IMemeFull>();
    const [rating, setRating] = useState(-1); // to distinguish local meme from global
    const [fetchMeme, isLoading, error] = useFetching(async () => {
        const res = await MemeService.getMeme(id, {langCode: localeStore.getMemeLanguage(), regCode: localeStore.getRegion()});
        setMeme(res);
        if (res.meme_rating_info.rating > 0) {
            setRating(Math.round(res.meme_rating_info.rating * 100));
        }
        if (setImgRatio) {
            setImgRatio(res.img_ratio);
        }
    });

    useEffect(() => {
        fetchMeme({});
    }, []);

    return (
        <>
            { meme &&
                <div className={cl.Container}>
                    { rating >= 0
                        ?
                        <div className={cl.PanelContainer}>
                            <div style={{width: "1px", height: "1px"}}></div>
                            <MemeRating ratingInfo={meme.meme_rating_info} ratingType={"rating"}/>
                            {/* <div className={cl.MemeRating}
                                 title={meme.meme_rating_info.is_global ? t("ui.memeRatingTitleGlobal") : t("ui.memeRatingTitleLocal")}>
                                <img style={{height: "1em"}} src={meme.meme_rating_info.is_global ? global_rating_img : rating_img} alt={"rating_img"}/>
                                <div>{rating + "%"}</div>
                            </div> */}
                        </div>
                        :
                        <div className={cl.PanelContainer} style={{justifyContent: "center"}}>
                            <div style={{fontSize: "var(--medium-font-size)",display: "flex", alignItems: "center"}}>
                                <img style={{height: "1em"}} src={local_img} alt={"local_img"}/>
                                <div style={{marginLeft: "1vmin"}}>{t("ui.localMeme")}</div>
                            </div>
                        </div>
                    }
                    <Headline titles={meme.titles}
                              contentType={meme.content_type}/>
                    <MemeContent meme={meme}
                                 autoplay={true}
                                 handleClick={handleClickOnContent}/>
                    <MemeBottomPanel isAuth={authStore.isAuth}
                                     memeDevId={meme.meme_dev_info.id}
                                     memeDevNick={meme.meme_dev_info.nick_name}
                                     memeDevAvatarUrl={meme.meme_dev_info.avatar_url}
                                     meme={meme}/>
                </div>
            }
            { isLoading && <Loader/> }
            { error &&
                <div className={cl.ModalMemeError}>
                    <div className={cl.Container + " " + cl.Error}>
                        <ErrorPage err={error}/>
                    </div>
                </div>
            }
        </>
    );
})

interface TopMemeProps {
    isAuth: boolean,
    meme: IMemeFull,
    position: number,
    ratingType: "hot" | "percent"
}

function TopMeme({isAuth, meme, position, ratingType}: TopMemeProps) {
    function HotImage() {
        const flame = meme.meme_rating_info.flame || 0
        return (
            <div className={cl.MemeHot}>
                <div className={cl.MemeRatingImgContainer}>
                    <img className={cl.MemeRatingImg + " " + cl.HotGreyFilterImg}
                         src={hot_img} alt={`hot_grey`} />
                    <img className={cl.HotColoredImg}
                         style={{height: `${flame}em`}}
                         src={hot_img} alt={`hot`} />
                </div>
            </div>
        );
    }

    return (
        <div className={cl.Container}>
            <div className={cl.PanelContainer}>
                <div className={cl.MemePosition}>
                    <div>{"# " + position}</div>
                </div>
                <div className={cl.HotMemeHotAndRatingContainer}>
                    <HotImage/>
                    <MemeRating ratingInfo={meme.meme_rating_info}
                                ratingType={"rating"}
                                ratingValueStyle={{fontSize: "0.75em"}}/>
                </div>
                {/* <div className={cl.MemeRating}
                     title={meme.meme_rating_info.is_global ? t("ui.memeRatingTitleGlobal") : t("ui.memeRatingTitleLocal")}>
                    <img style={{height: "1em"}} src={meme.meme_rating_info.is_global ? global_rating_img : rating_img} alt={"rating_img"}/>
                    <div>{rating + "%"}</div>
                </div> */}
            </div>
            <Headline titles={meme.titles}
                      contentType={meme.content_type}/>
            <MemeContent meme={meme}
                         autoplay={true}/>
            <MemeBottomPanel isAuth={isAuth}
                             memeDevId={meme.meme_dev_info.id}
                             memeDevNick={meme.meme_dev_info.nick_name}
                             memeDevAvatarUrl={meme.meme_dev_info.avatar_url}
                             meme={meme}/>
        </div>

    );
}

export interface MemeBottomPanelProps {
    isAuth: boolean,
    memeDevId: number,
    memeDevNick: string,
    memeDevAvatarUrl: string,

    meme: IMeme,
    containerStyle?: CSSProperties,
    scale?: number,
    doWhenReport?(): Promise<void>,
}

export function MemeBottomPanel(props: MemeBottomPanelProps) {
    const { t } = useTranslation();
    const avatarSizeStyle: CSSProperties = {
        height: "2.5em",  width: "2.5em", objectFit: "cover", borderRadius: "50%"
    }
    const [isTagsShown, setIsTagShown] = useState(false);

    return (
        <>
            <div className={cl.BottomPanelContainer}
                 style={{...props.containerStyle, fontSize: props.scale ? `calc(var(--medium-font-size) * ${props.scale})` : "var(--medium-font-size)"}}>
                <div className={`${cl.PanelBottomLeft}`}>
                    <NavLink className={`${cl.UserInfo}`}
                             style={{color: "var(--std-text-color)", textDecoration: "none"}}
                             to={`/meme-devs/${props.memeDevId}`}>
                            <ImageLoader key={props.memeDevId}
                                         imgStyle={avatarSizeStyle}
                                         animStyle={avatarSizeStyle}
                                         url={props.memeDevAvatarUrl}
                                         altUrl={def_avatar_img}
                                         alt="avatar"/>
                            <div className={cl.Nickname}>
                                {props.memeDevNick}
                            </div>
                    </NavLink>
                </div>
                <div className={cl.PanelBottomRight}>
                    <ExportMemeBtn meme={props.meme}
                                   fileName={getMemeExportFileName(false, props.meme.id)}/>
                    <ShareBtn link={getMemePageLink(props.meme.id)}
                              callback={async () => await BadgeService.shareMeme(props.meme.id)}/>
                    { props.isAuth && <AdditionallyBtn elems={
                        [
                            <ShowTagsBtn key={2}
                                         callback={() => setIsTagShown(!isTagsShown)}
                                         title={isTagsShown ? t("ui.tag.hideTags") : t("ui.tag.showTags")}/>,
                            <ReportBtn key={1}
                                       memeId={props.meme.id}
                                       displayTitle={true}
                                       doWhenMemeReport={props.doWhenReport}/>,
                        ]
                    }/>}
                </div>
            </div>
            <TagsPanel tags={props.meme.meme_addon.tags || []}
                       blockedTags={props.meme.meme_addon.blocked_tags || []}
                       isTagsShown={isTagsShown}/>
        </>
    );
}

interface TagsPanelProps {
    tags: string[],
    blockedTags: string[],
    isTagsShown: boolean,
}

interface TagProps {
    isBlocked: boolean,
    tag: string,
}

export function TagsPanel({tags, blockedTags, isTagsShown}: TagsPanelProps) {
    function Tag({isBlocked, tag}: TagProps) {
        const { t } = useTranslation();
        const [isTagBlocked, setIsTagBlocked] = useState(isBlocked); //do additionally control this state
        const [borderColor, setBorderColor] = useState(isBlocked ? "var(--red-color)" : "darkgrey");
        const [blockTag, isBlockPending, blockTagError] = useFetching(async () => {
            setBorderColor("var(--red-color)");
            setIsTagBlocked(true);
            await TagService.blockTag(tag);
            toast.success(i18n.t("ui.tag.tagBlocked"), {autoClose: 2000});
        });
        const [unblockTag, isUnblockPending, unblockTagError] = useFetching(async () => {
            setBorderColor("darkgrey");
            setIsTagBlocked(false);
            await TagService.unblockTag(tag);
            toast.success(i18n.t("ui.tag.tagUnblocked"), {autoClose: 1000});
        });
        function handleClick() {
            if (isBlockPending || isUnblockPending)
                return;
            if (isTagBlocked) {
                unblockTag({});
            } else {
                blockTag({});
            }
        }

        useEffect(() => {
            if (blockTagError || unblockTagError) {
                toast.error(i18n.t("ui.defErrMsg"));
            }
        }, [blockTagError, unblockTagError]);

        return (
            <div className={cl.TagContainer}
                 title={isTagBlocked ? t("ui.tag.clickToUnblock") : t("ui.tag.clickToBlock")}
                 style={{borderColor: borderColor}}
                 onClick={handleClick}>
                { tag }
            </div>
        );
    }

    return (
        <div className={cl.TagPanelContainer}
             style={{maxHeight: isTagsShown ? "15em" : "0"}}>
            { tags.map(tag =>
                <Tag key={tag}
                     isBlocked={false}
                     tag={tag}/>) }
            { blockedTags.map(blockedTag =>
                <Tag key={blockedTag}
                     isBlocked={true}
                     tag={blockedTag}/>) }
        </div>
    );
}

export function getMemeExportFileName(withTimeStamp: boolean, memeId?: number) {
    let name = "meme";
    if (memeId) {
        name = `${name}_${memeId}`
    }
    if (withTimeStamp) {
        const now = new Date();
        const YY = now.getFullYear();
        const MM = (now.getMonth() + 1).toString().padStart(2, '0');
        const DD = (now.getDate()).toString().padStart(2, '0');
        const hh = (now.getHours()).toString().padStart(2, '0');
        const mm = (now.getMinutes()).toString().padStart(2, '0');
        const ss = (now.getSeconds()).toString().padStart(2, '0');
        name = `${name}_${YY}-${MM}-${DD}_${hh}-${mm}-${ss}`
    }

    return name;
}

interface HeadlineProps {
    titles: ITitle[],
    contentType: contentType,
}

export function Headline({titles, contentType}: HeadlineProps) {
    if (!titles) {
        titles = []
    }
    if (contentType === "IMG" || titles.length === 0) {
        return (
            <div className={cl.MemeTopPadding}></div>
        );
    }
    if (contentType === "MP4" || contentType === "GIF") {
        return (
            <>
                <div className={cl.MemeWithHeadlineTitleTopPadding}></div>
                <div className={cl.HeadlineTitle}>{ titles[0].text }</div>
            </>
        );
    }
    return (<></>);
}

export interface MemeContentProps {
    meme: IMeme,
    forceShowAnimation?: boolean,
    handleClick?(): void,
    autoplay?: boolean
}

export function MemeContent({meme, forceShowAnimation, handleClick, autoplay}: MemeContentProps) {
    // Temporarily. Need to implement converter pattern
    if (!meme.content_type) {
        meme.content_type = "IMG";
    }

    if (meme.content_type === "IMG" || meme.content_type === "GIF" ) {
        return (
            <>
                <MemeImage id={meme.id}
                           url={meme.url}
                           imgRatio={meme.img_ratio}
                           contentType={meme.content_type}
                           titles={meme.titles}
                           forceShowAnimation={forceShowAnimation}
                           handleClick={handleClick}/>
            </>
        );
    }
    if (meme.content_type === "MP4") {
        return (
            <>
                <MemeVideo titles={meme.titles}
                           url={meme.url}
                           autoplay={autoplay}
                           imgRatio={meme.img_ratio}
                           forceShowAnimation={forceShowAnimation}/>
            </>
        );
    }

    console.log("Meme content type:", meme.content_type)
    return (
        <>
            Unknown meme content type
        </>
    );
}

interface MemeVideoProps {
    titles: ITitle[],
    url: string,
    imgRatio: number,
    autoplay?: boolean,
    forceShowAnimation?: boolean,
}

export function MemeVideo({titles, url, imgRatio, autoplay, forceShowAnimation}: MemeVideoProps) {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (forceShowAnimation) {
            return;
        }

        const videoElement = videoRef.current;
        if (!videoElement) return;
        // Callback для Intersection Observer
        const handleIntersection: IntersectionObserverCallback = (entries) => {
            if (!autoplay) {
                return
            }
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Если элемент видим, включаем воспроизведение
                    videoElement.play().catch((err) => {
                        console.error("Ошибка воспроизведения видео:", err);
                    });
                } else {
                    // Если элемент выходит за пределы экрана, ставим на паузу
                    videoElement.pause();
                }
            });
        };
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.75, // Срабатывание, если хотя бы 75% элемента видны
        });
        observer.observe(videoElement);

        return () => {
            observer.unobserve(videoElement);
        };
    }, [forceShowAnimation]);

    return (
        <>
            <VideoPlayer videoRef={videoRef}
                         autoplay={autoplay || false}
                         url={url}
                         imgRatio={imgRatio}
                         forceShowAnimation={forceShowAnimation}/>
        </>
    );
}

interface VideoPlayerProps {
    videoRef?: RefObject<HTMLVideoElement>,
    autoplay: boolean,
    url: string,
    imgRatio: number,
    forceShowAnimation?: boolean,
}

export function VideoPlayer({videoRef, autoplay, url, imgRatio, forceShowAnimation}: VideoPlayerProps) {
    return (
        <>
            {/*additional <div> wrapper is needed to avoid incorrect resizing of the player after exiting fullscreen mode*/}
            {/*<div style={{width: "100%", maxHeight: "100%"}}>*/}
            <div>
                <ImageLoader url={url}
                             imgStyle={{
                                 width: "100%",
                                 // maxHeight: "100%",
                                 // objectFit: "contain",
                                 // transition: "none",
                             }}
                             animStyle={{
                                 width: "100%",
                                 paddingBottom: `${100 / imgRatio}%`
                             }}
                             alt={""}
                             videoRef={videoRef}
                             autoplay={autoplay}
                             isVideo={true}
                             forceShowAnimation={forceShowAnimation}/>
                {/*<video ref={videoRef}*/}
                {/*       controls={true}*/}
                {/*       muted={autoplay}*/}
                {/*       src={url}*/}
                {/*       style={{*/}
                {/*           width: "100%",*/}
                {/*           maxHeight: "100%",*/}
                {/*           objectFit: "contain",*/}
                {/*           transition: "none",*/}
                {/*       }}>*/}
                {/*    Video unsupported by your browser*/}
                {/*</video>*/}
            </div>
        </>
    );
}

export interface MemeImageProps {
    id: number,
    url: string,
    imgRatio: number,
    contentType: contentType,
    titles: ITitle[],
    forceShowAnimation?: boolean,
    handleClick?(): void,
}

export function MemeImage({id, url, imgRatio, contentType, titles, forceShowAnimation, handleClick}: MemeImageProps) {
    const imgContainer = useRef<HTMLDivElement>(null);
    const [imgContainerSize, setImgContainerSize] = useState<Size | null>(null);

    // if user changes window size
    // or if user loads new image
    // or if img_ratio returned from server is incorrect
    // it is necessary to recalculate container size and titles positions
    function handleResize(entries: ResizeObserverEntry[]) {
        const {width, height} = entries[0].contentRect;
        setImgContainerSize({width: width, height: height});
    }
    useResizing(imgContainer, handleResize);

    let imgStyle: CSSProperties = {width: "100%"}
    let animStyle: CSSProperties = {width: "100%", paddingBottom: `${100 / imgRatio}%`}
    let contentStyle: CSSProperties = handleClick ? {cursor: "pointer"} : {}

    return (
        <div className={cl.MemeContent}
             ref={imgContainer}
             style={contentStyle}
             onClick={handleClick}>
            <ImageLoader key={id}
                         imgStyle={imgStyle}
                         animStyle={animStyle}
                         alt={"meme"}
                         url={url}
                         forceShowAnimation={forceShowAnimation}
                         ImgAddon={
                             <>
                                 { contentType === "IMG" &&
                                     titles.map((title, index)  =>
                                         <Title title={title}
                                                imgContainerSize={imgContainerSize}
                                                key={`${id}-${index}`}/>)
                                 }
                             </>
                         }/>
        </div>

    );
}


interface TitleProps {
    title: ITitle,
    imgContainerSize: Size | null,
}

export function Title({title, imgContainerSize}: TitleProps) {
    const [calculatedTitleProps, setCalculatedTitleProps] = useState({
        left: 0,
        bottom: 0,
        fontSize : 0,
    });

    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        document.fonts.ready.then(function() {
            // Здесь выполняйте расчеты размеров текста
            setFontsLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (imgContainerSize && imgContainerSize.height > 0) {
            const {fontSize, textAreaSize} = getCorrectedFontAndTextAreaSize(title, "", imgContainerSize, strokeSize);
            // console.log(textAreaSize)
            const {left, bottom} = getTextAreaPosition(title, imgContainerSize, textAreaSize);
            setCalculatedTitleProps({
                left: left,
                bottom: bottom,
                fontSize: fontSize * imgContainerSize.height,
            });
            // calculatedTitleProps.left = left;
            // calculatedTitleProps.bottom = bottom;
            // calculatedTitleProps.fontSize = fontSize * imgContainerSize.height;
        }
    }, [fontsLoaded, imgContainerSize?.height]);

    return (
        <div style={{
            position: "absolute",
            whiteSpace: "pre",
            textAlign: "center",
            left: calculatedTitleProps.left + "px",
            bottom: calculatedTitleProps.bottom + "px",
            fontSize: calculatedTitleProps.fontSize + "px",
            color: "#" + argbToRgba(title.text_color.toString(16)),
            fontFamily: title.font,
            textShadow: `${shadowSize} ${getTextShadowColor(title.text_color.toString(16))}`,
            // textShadow: `0 0 ${strokeSize} ${getTextShadowColor(title.text_color.toString(16))}`,
            // WebkitTextStrokeColor: `${getStrokeColor(title.text_color.toString(16))}`,
            // WebkitTextStrokeWidth: `${strokeSize}`,
        }}>
            {/*show text and extra space if there is a line break at the end*/}
            {`${title.text}${title.text.charAt(title.text.length - 1) === "\n" ? " " : ""}`}
        </div>
    )
}

export const strokeSize = "calc(0.03em + 1px)"
export const shadowSize = "1px 1px calc(0.02em + 1px)"

export function argbToRgba(argbHex: string): string {
    if (argbHex.length > 8) {
        throw new Error("incorrect hex color code");
    }
    return argbHex.substring(2) + argbHex.substring(0, 2)
}

export function getStrokeColor(argbHex: string): string {
    if (argbHex.length > 8) {
        throw new Error("incorrect hex color code");
    }
    const rgbInt = parseInt(argbHex.substring(2), 16)
    if (rgbInt > 9_000_000) {
        return "rgba(0, 0, 0, 0.3)"
    } else {
        return "rgba(255, 255, 255, 0.3)"
    }
}

export function getTextShadowColor(argbHex: string): string {
    if (argbHex.length > 8) {
        throw new Error("incorrect hex color code");
    }
    const rgbInt = parseInt(argbHex.substring(2), 16)
    if (rgbInt > 9_000_000) {
        return "rgba(0, 0, 0, 1)"
    } else {
        return "rgba(255, 255, 255, 1)"
    }
}