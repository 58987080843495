import $api from "../http";
import IMemeFull, {
    CreateMemeData, CreateVideoMemeData,
    IMemeFullList,
    IMemeWithRatingList,
} from "../models/Memes";
import {CreateResponse} from "../models/CreateResponse";
import {Locale} from "../models/Locale";

export type sortDevMemes = "first-fresh" | "first-old" | "rating-down" | "rating-up"

export default class MemeService {
    static async createMeme(data: CreateMemeData) {
        return await $api.postForm<CreateResponse>("/v1/memes", data);
    }
    static async createVideoMeme(data: CreateVideoMemeData) {
        return await $api.postForm<CreateResponse>("/v1/memes/video", data);
    }
    static async getMeme(id: number, locale: Locale) {
        const response = await $api.get<IMemeFull>(`/v1/memes/${id}?lang=${locale.langCode}&reg=${locale.regCode}`);
        return response.data;
    }

    static async getDuncanChestMemes(locale: Locale, contentTypes: string) {
        const response = await $api.get<IMemeFull[]>(`/v1/top/duncan-chest?content-types=${contentTypes}&lang=${locale.langCode}&reg=${locale.regCode}`);
        return response.data;
    }
    static async getTopMemes(locale: Locale, page: number, contentTypes: string) {
        const limit = 10;
        const response =
            await $api.get<IMemeFullList>(`/v1/top/top-memes?content-types=${contentTypes}&lang=${locale.langCode}&reg=${locale.regCode}&offset=${page*limit}&limit=${limit}`);
        return response.data;
    }
    static async getMemeDevMemes(memeDevId: number, locale: Locale, page: number, contentTypes: string, sort: sortDevMemes) {
        const limit = 10;
        const response =
            await $api.get<IMemeWithRatingList>(`/v1/memes/meme-dev/${memeDevId}?content-types=${contentTypes}&lang=${locale.langCode}&reg=${locale.regCode}&offset=${page*limit}&limit=${limit}&sorted=${sort}`);
        return response.data;
    }
    static async deleteMeme(memeId: number) {
        return await $api.delete(`/v1/memes/${memeId}`)
    }
}