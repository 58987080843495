import cl from "./Btn.module.css"
import delete_img from "../../images/report/spam.png";
import React, {useEffect, useRef, useState} from "react";
import {useFetching} from "../../../hooks/useFetching";
import MemeService from "../../../services/MemeService";
import {ErrorNotify} from "../ErrorPage";
import {Id, toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {LyingSeparator} from "../Separator";
import {createPortal} from "react-dom";

interface DeleteMemeBtnProps {
    memeId: number,
    displayTitle?: boolean,
    callOnDelete(): void
}

export function DeleteMemeBtn({memeId, displayTitle, callOnDelete}: DeleteMemeBtnProps) {
    const { t } = useTranslation();
    const [isModal, setIsModal] = useState(false);
    return (
        <div className={cl.MemePanelBtn}
             onClick={() => setIsModal(true)}>
            <img className={cl.MemePanelBtnImg}
                 src={delete_img} alt="delete"/>
            { displayTitle && <div className={cl.MemePanelBtnTitle}>{t("ui.delete")}</div> }
            <DeleteMemeModal memeId={memeId}
                             callOnDelete={callOnDelete}
                             isVisible={isModal}
                             setIsVisible={setIsModal}/>
        </div>
    );
}

interface DeleteMemeModalProps extends DeleteMemeBtnProps {
    isVisible: boolean,
    setIsVisible(b: boolean): void,
}

function DeleteMemeModal({memeId, callOnDelete, isVisible, setIsVisible}: DeleteMemeModalProps) {
    const { t } = useTranslation();
    const infoToastId = useRef<Id | null>(null);
    const [deleteMeme, isPending, error] = useFetching(async () => {
        const notify = () => infoToastId.current = toast.info(t("ui.pending"), {autoClose: 1000});
        notify();
        await MemeService.deleteMeme(memeId);
        callOnDelete();
        toast.dismiss(infoToastId.current as Id);
        toast.success(t("profile.memes.successDeletion"), {autoClose: 1000});
        setIsVisible(false);
    })
    useEffect(() => {
        if (error) {
            toast.dismiss(infoToastId.current as Id);
            ErrorNotify({err: error})
        }
    }, [error]);

    return (
        <>
            { createPortal(
                <div className={`${cl.ModalBG} ${isVisible ? cl.active : ""}`}
                     onMouseDown={() => setIsVisible(false)}>
                    <div className={cl.ModalContainer}
                         onMouseDown={(e) => e.stopPropagation()}>
                        <div className={cl.ModalTitle}>{ t("profile.memes.areYouSureDeleteMeme") }</div>
                        <LyingSeparator/>
                        <button className={cl.ModalButton}
                                onClick={() => deleteMeme({})}>
                            { t("ui.delete") }
                        </button>
                    </div>
                </div>,
                document.body)
            }
        </>
    );
}