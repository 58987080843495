import {IMemeDev} from "../models/MemeDevs";
import {makeAutoObservable} from "mobx";
import IMemeFull from "../models/Memes";
import {defMemeLang} from "./localeStore";

export interface HotMemesCache {
    memes: IMemeFull[],
    lang: string,
    page: number,
    limit: number,
    total: number,
    scrollY: number,
}

export interface TopMemeDevsCache {
    memeDevs: IMemeDev[],
    page: number,
    limit: number,
    total: number,
    scrollY: number,
}

export const defHotMemesCache = { memes: [], lang: defMemeLang, page: 0, limit: 0, total: 0, scrollY: 0 };
export const defTopMemeDevsCache = { memeDevs: [], page: 0, limit: 0, total: 0, scrollY: 0 };

export class Cache {
    memeDev: IMemeDev | null = null;
    hotMemes: HotMemesCache = defHotMemesCache;
    topMemeDevs: TopMemeDevsCache = defTopMemeDevsCache;

    constructor() {
        makeAutoObservable(this);
    }

    setMemeDev(user: IMemeDev) {
        this.memeDev = user;
    }

    getMemeDev(id: number) {
        if (this.memeDev) {
            return this.memeDev.id === id ? this.memeDev : null;
        }

        return null;
    }

    setHotMemes(hotMemes: HotMemesCache) {
        this.hotMemes = hotMemes
    }

    resetHotMemes() {
        this.hotMemes = defHotMemesCache
    }

    getHotMemes() {
        return this.hotMemes
    }

    setTopMemeDevs(topMemeDevs: TopMemeDevsCache) {
        this.topMemeDevs = topMemeDevs
    }

    resetTopMemeDevs() {
        this.topMemeDevs = defTopMemeDevsCache
    }

    getTopMemeDevs() {
        return this.topMemeDevs
    }
}